import axios from 'axios';
import {url} from './ApiConstant';

export async function findAll() {
  try {
    const response = await axios.get(
        `${url}/sticker-image`,
        {
          headers: {},
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
    }
  }
}

export async function create(
    missionId,
    content,
    imageUrl,
    token, navigate) {
  try {
    const response = await axios.post(
        `${url}/missions-record`,
        {
          missionId,
          content,
          imageUrl,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}

export async function update(
    missionId,
    missionRecordId,
    content,
    imageUrl,
    token, navigate) {
  try {
    const response = await axios.post(
        `${url}/missions-record/${missionRecordId}`,
        {
          missionId,
          content,
          imageUrl,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}

export async function isOwnerMissionRecord(
    missionRecordId,
    token,
    navigate) {
  try {
    const response = await axios.get(
        `${url}/missions-record/is-owner/${missionRecordId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}
