import React, {useEffect, useState} from 'react';
import ic_left from "../../../images/ic_left.png";
import ic_right from "../../../images/ic_right.png";
import MissionDetailUserProfile from "./MissionDetailUserProfile";
import MissionDetailDateItem from "./MissionDetailDateItem";
import {
  findMissionDetail,
  findMissionDetailDate
} from "../../../api/MissionDetailPageApi";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getMemberInfo} from "../../../api/ProfileSetupPageApi";

const MissionDetailDatePage = ({missionId, memberId}) => {

  const [requestMemberId, setRequestMemberId] = useState(""); // 생년월일 상태 관리

  const getKoreanDate = () => {
    const now = new Date();
    const kstOffset = 9 * 60; // 한국 timezone offset (9시간)
    const utc = now.getTime() + (now.getTimezoneOffset() * 60 * 1000); // UTC 시간 구하기
    return new Date(utc + (kstOffset * 60 * 1000)); // KST 적용
  };

  const [date, setDate] = useState(getKoreanDate());
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [detailDate, setDetailDate] = useState([]);

  const loadData = async () => {
    try {
      const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      const response = await findMissionDetailDate(missionId, memberId, yearMonth, token, navigate);
      const memberInfo = await getMemberInfo(token);
      setRequestMemberId(memberInfo.memberId);
      setDetailDate(response.missionDetailDateResponses);
    } catch (error) {
      console.error('데이터 로드 실패:', error);
    }
  };

  useEffect(() => {
    loadData();
  }, [token, navigate, missionId, memberId, date]);


  const handlePrevMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1));
  };

  return (
      <div>
        <div
            style={{
              backgroundColor: '#FFF',
              marginLeft: '16px',
              marginRight: '16px',
              borderRadius: '8px',
            }}
        >
          <div
              style={
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '30px',      // 높이 추가
                  alignItems: 'center', // 세로 중앙 정렬
                  marginTop: '20px',
                  paddingTop: '10px',
                }
              }
          >

            <img
                src={ic_left}
                onClick={handlePrevMonth}
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: 'auto',
                  marginRight: '8px',
                }}
            />
            <p
                style={{
                  color: '#3B4856',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  letterSpacing: '-0.48px',
                }}
            >
              {date.getFullYear()}년 {date.getMonth() + 1}월
            </p>

            <img
                src={ic_right}
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: 'auto',
                  marginLeft: '8px',
                }}
                onClick={handleNextMonth}
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)',
              marginLeft: '20px',
              marginRight: '20px',
              marginTop: '20px',
              marginBottom: '10px',
            }}>
              {detailDate.map((data) => (
                  <MissionDetailDateItem
                      key={data.id}
                      missionId={missionId}
                      today={data.today}
                      imageUrl={data.imageUrl}
                      missionRecordId={data.missionRecordId}
                      content={data.content}
                      memberId={memberId}
                      requestMemberId={requestMemberId}
                      onRecordUpdate={loadData}  // 이 prop 추가
                      {...data}
                  />
              ))}
            </div>
          </div>
        </div>
      </div>
  )
}

export default MissionDetailDatePage;
