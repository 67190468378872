import React, { useEffect, useState } from 'react';
import "./MissionCreateTitle.css";

const MissionCreateTitle = ({ onSubmit, initialValue = '' }) => {
  const [nickname, setNickname] = useState(initialValue);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // 4-16자 검증
    setIsValid(nickname.length >= 4 && nickname.length <= 16);
  }, [nickname]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 16) {  // 최대 16자까지만 입력 가능
      setNickname(value);
    }
  };

  const handleSubmit = () => {
    if (isValid) {
      onSubmit(nickname);
    }
  };

  // 테두리 색상 결정
  const getBorderColor = () => {
    if (nickname.length > 0) {
      return !isValid ? '#FF5C2F' : '#B2BFCC';
    }
    return '#CFD9E2';  // 기본 상태
  };

  return (
      <div>
        <div
            style={{
              marginTop: '32px',
              marginLeft: '16px',
              marginBottom: '0px',
            }}
        >
        <span
            style={{
              color: '#3B4856',
              fontFamily: 'Pretendard',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '32px',
              letterSpacing: '-0.72px',
            }}
        >프렌즈와 </span>
          <span
              style={{
                color: '#227AF3',
                fontFamily: 'Pretendard',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '32px',
                letterSpacing: '-0.72px',
              }}
          >함께 달성할<br/> 미션을 입력</span>
          <span
              style={{
                color: '#3B4856',
                fontFamily: 'Pretendard',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '32px',
                letterSpacing: '-0.72px',
              }}
          >해 주세요.</span>
        </div>

        <p
            style={{
              color: '#4A5B6C',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '-0.48px',
              marginLeft: '16px',
              marginTop: '40px',
              marginBottom: '0px',
            }}
        >
          미션명 입력
        </p>

        <input
            type="text"
            value={nickname}
            placeholder="미션명 입력"
            className="mission-input"
            style={{
              width: 'calc(100% - 32px)',
              marginTop: '8px',
              padding: '14px 12px',
              boxSizing: 'border-box',
              borderRadius: '8px',
              border: `1px solid ${getBorderColor()}`,
              fontSize: '14px',
              fontStyle: 'normal',
              fontFamily: 'Pretendard',
              fontWeight: 500,
              color: '#4A5B6C',
              outline: 'none',
              lineHeight: '20px',
              letterSpacing: '-0.42px',
              marginLeft: '16px',
              marginRight: '16px',
            }}
            onChange={handleInputChange}
        />

        {nickname.length > 0 && (
            <div style={{
              fontSize: '14px',
              marginTop: '8px',
              color: !isValid ? '#FF5C2F' : '#4A5B6C',
              marginLeft: '16px',
              fontFamily: 'Pretendard',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.42px',
            }}>
              4-16자 이내로 입력해 주세요. ({nickname.length}/16)
            </div>
        )}

        <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              marginLeft: '16px',
              marginRight: '16px',
            }}
        >
          <button
              disabled={!isValid}
              onClick={handleSubmit}
              style={{
                height: '48px',
                backgroundColor: isValid ? '#227AF3' : '#CFD9E2',
                color: isValid ? "white" : '#7E90A4',
                border: 'none',
                marginTop: '46px',
                fontSize: '16px',
                borderRadius: '8px',
                width: '100%',
                cursor: isValid ? 'pointer' : 'default',
              }}
          >
            다음
          </button>
        </div>
      </div>
  );
};

export default MissionCreateTitle;
