import React, { useRef } from 'react';
import AppleLogin from 'react-apple-login';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginToken } from "../../redux/UserStore";

const REDIRECT_URI = "https://one-daily-app.shop/auth/login/apple";
// const REDIRECT_URI = "https://4dbc-175-124-105-15.ngrok-free.app/routine/auth/login/apple";

const AppleLoginButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cardRef = useRef(null);

  const handleCallback = async (response) => {
    try {
      const { code, id_token } = response.authorization;
      const res = await fetch('https://one-daily-app.shop/api/auth/apple/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code, id_token })
      });

      const data = await res.json();
      dispatch(LoginToken(data.accessToken));
      navigate("/main");
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const renderProp = (props) => {
    return (
        <button
            style={{
              width: '200px',
              height: '50px',
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            onClick={props.onClick}
            disabled={props.disabled}
        >
          Sign in with Apple
        </button>
    );
  };

  return (
      <div ref={cardRef} style={{ width: '200px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <AppleLogin
            clientId="com.missionmate.login-service"
            redirectURI={REDIRECT_URI}
            responseType="code id_token"
            responseMode="query"
            usePopup={true}
            callback={handleCallback}
            render={renderProp}
        />
      </div>
  );
};

export default AppleLoginButton;
