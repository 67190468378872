import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {LoginToken} from "../../redux/UserStore";
import {url} from "../../api/ApiConstant";
import loginButton from "../../images/btn_google_login.png";

// Google OAuth 2.0 클라이언트 ID와 리디렉션 URI
const CLIENT_ID = '582717791354-mjgbs2ljlau7hon5p05re0mcndmosp91.apps.googleusercontent.com';
// const REDIRECT_URI = 'http://localhost:3000/mission-mate/auth/login/google'; // 자신의 리디렉션 URI로 변경
const REDIRECT_URI = 'https://one-daily-app.shop/mission-mate/auth/login/google'; // 자신의 리디렉션 URI로 변경

const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // 로그인 버튼 클릭 시 Google OAuth 인증 페이지로 리디렉션
  const handleLogin = () => {
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=profile%20email&access_type=offline`;
  };

  // 인증 코드 처리
  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      fetch(`${url}/auth/google/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
      .then(response => response.json())
      .then(async (data) => {
        const accessToken = data.accessToken;
        dispatch(LoginToken(accessToken));
        navigate("/mission-mate");
      })
      .catch(error => {
        console.error('Error exchanging code for token:', error);
      });
    }
  }, [searchParams, navigate, dispatch]);

  return (
      <div>
        <button onClick={handleLogin} style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
          <img
              src={loginButton}
              alt="Sign in with Google"
              style={{
                width: '328px',
                height: '52px',
              }}
          />
        </button>
      </div>
  );
};

export default GoogleLoginButton;
