import React, {useEffect, useState} from "react";
import ic_left from "../../images/ic_left.png";
import ic_right from "../../images/ic_right.png";
import ic_down from "../../images/ic__down.png";
import homeIndicator from "../../images/Home Indicator.png";

const BirthdayBottomSheet = ({isOpen, onClose, onSelect, defaultValue}) => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [yearRange, setYearRange] = useState([1990, 2001]);
  const [step, setStep] = useState('year');

  useEffect(() => {
    if (isOpen && defaultValue) {
      const match = defaultValue.match(/(\d{4})년\s+(\d{1,2})월/);
      if (match) {
        const year = parseInt(match[1]);
        const month = parseInt(match[2]);
        setSelectedYear(year);
        setSelectedMonth(month);

        // yearRange 조정
        const startYear = Math.floor(year / 12) * 12;
        setYearRange([startYear, startYear + 11]);
      }
    }
  }, [isOpen, defaultValue]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedYear(null);
      setSelectedMonth(null);
      setStep('year');
    }
  }, [isOpen]);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
  };

  const handleYearConfirm = () => {
    setStep('month');
  };

  const handleMonthConfirm = () => {
    if (selectedMonth) {
      onSelect(`${selectedYear}년 ${selectedMonth}월`);
      onClose();
      setStep('year');
      setSelectedYear(null);
      setSelectedMonth(null);
    }
  };

  const handlePrevYears = () => {
    setYearRange([yearRange[0] - 12, yearRange[1] - 12]);
  };

  const handleNextYears = () => {
    setYearRange([yearRange[0] + 12, yearRange[1] + 12]);
  };

  const handleStepYearSelect = () => {
    setStep('year');
  }

  const generateYears = () => {
    const years = [];
    for (let year = yearRange[0]; year <= yearRange[1]; year++) {
      years.push(year);
    }
    return years;
  };

  const months = Array.from({length: 12}, (_, i) => i + 1);

  if (!isOpen) {
    return null;
  }

  return (
      <>
        <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(22, 22, 22, 0.80)",
              zIndex: 999
            }}
            onClick={onClose}
        />
      <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.1)',
            zIndex: 1000
          }}
      >
        <div
            style={{
              display: "flex",
              marginTop: '7px',
              justifyContent: "center",
            }}
        >
          <img
              src={homeIndicator}
              style={{
                width: "64px",
                height: "5px",
              }}
              alt="indicator"
          />
        </div>

        {step === 'year' ? (
            <>
              <div style={{marginBottom: '20px',
              marginTop: '20px'}}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px'
                }}>
                  <p style={{
                    color: '#29333E',
                    fontFamily: 'Pretendard',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '26px',
                    letterSpacing: '-0.54px',
                    marginLeft: '16px',
                  }}>
                    {yearRange[0]}-{yearRange[1]}년
                  </p>

                  <div>
                    <img
                        src={ic_left}
                        style={{
                          width: '16px',
                          height: '16px',
                          cursor: 'pointer'
                        }}
                        onClick={handlePrevYears}
                    />
                    <img
                        src={ic_right}
                        style={{
                          width: '16px',
                          height: '16px',
                          cursor: 'pointer',
                          marginLeft: '16px',
                          marginRight: '16px'
                        }}
                        onClick={handleNextYears}
                    />
                  </div>
                </div>

                <div style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '8px',
                  padding: '0 16px'
                }}>
                  {generateYears().map(year => (
                      <button
                          key={year}
                          onClick={() => handleYearSelect(year)}
                          style={{
                            height: '44px',
                            backgroundColor: selectedYear === year ? '#E5F2FC' : 'white',
                            color: selectedYear === year ? '#227AF3' : '#29333E',
                            border: 'none',
                            borderRadius: '50px',
                            fontFamily: 'Pretendard',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '22px',
                            letterSpacing: '-0.45px',
                          }}
                      >
                        {year}년
                      </button>
                  ))}
                </div>
              </div>

              <div
                  style={
                    {
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }
                  }
              >
                <button
                    onClick={handleYearConfirm}
                    disabled={!selectedYear}
                    style={{
                      width: '100%',
                      height: '48px',
                      backgroundColor: '#227AF3',
                      color: 'white',
                      border: 'none',
                      marginTop: '20px',
                      marginLeft: '16px',
                      marginRight: '16px',
                      borderRadius: '8px',
                      marginBottom: '48px',
                    }}
                >
                  선택하기
                </button>
              </div>
            </>
        ) : (
            <>
              <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0',
                    marginTop: '15px',
                  }}
              >
                <p
                    style={
                        {
                          color: '#29333E',
                          fontFamily: 'Pretendard',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: '26px',
                          letterSpacing: '-0.54px',
                          marginLeft: '16px',
                        }
                    }

                >
                  {selectedYear}년
                </p>

                <img
                    src={ic_down}
                    style={{
                      width: '16px',
                      height: '16px',
                      marginLeft: '8px',
                    }}
                    onClick={handleStepYearSelect}
                />

              </div>

              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '8px',
                padding: '0 16px'
              }}>
                {months.map(month => (
                    <button
                        key={month}
                        onClick={() => handleMonthSelect(month)}
                        style={{
                          padding: '8px',
                          backgroundColor: selectedMonth === month ? '#E5F2FC' : 'white',
                          color: selectedMonth === month ? '#227AF3' : '#29333E',
                          border: 'none',
                          borderRadius: '50px',
                          fontFamily: 'Pretendard',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '22px',
                          letterSpacing: '-0.45px',
                        }}
                    >
                      {month}월
                    </button>
                ))}
              </div>

              <div
                  style={
                    {
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }
                  }
              >
                <button
                    onClick={handleMonthConfirm}
                    disabled={!selectedMonth}
                    style={{
                      width: '100%',
                      height: '48px',
                      backgroundColor: '#227AF3',
                      color: 'white',
                      border: 'none',
                      marginTop: '35px',
                      marginLeft: '16px',
                      marginRight: '16px',
                      borderRadius: '8px',
                      marginBottom: '48px',
                    }}
                >
                  선택하기
                </button>
              </div>
            </>
        )}
      </div>
      </>
  );
};

export default BirthdayBottomSheet;
