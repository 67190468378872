import React, {useState} from 'react';
import ic_back from "../../images/ic_back.png";
import ic_right from "../../images/ic_right.png";
import ProfileSettingUserInfo from "./ProfileSettingUserInfo";
import PopupPage from "../common/PopupPage";
import {LogoutUser} from "../../redux/UserStore";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteMember} from "../../api/ProfileSetupPageApi";

const UserSettingPage = () => {
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [deleteUserPopupOpen, setDeleteUserPopupOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    // 로그아웃 처리 로직
    dispatch(LogoutUser());
    navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
  };

  const handleDeleteUser = () => {
    // 로그아웃 처리 로직
    dispatch(LogoutUser());
    deleteMember(token).then(r => {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    })
  };

  return (
      <div>

        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '21px',
              position: 'relative',
            }}
        >
          <img
              onClick={() => navigate('/mission-mate/mission-list')}
              src={ic_back}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '16px',
                position: 'absolute',
              }}
          />
          <p style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '26px',
            letterSpacing: '-0.54px',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}>
            설정
          </p>
        </div>

        <p
            style={{
              color: '#7E90A4',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '-0.42px',
              marginLeft: '16px',
              marginTop: '36px',
            }}
        >
          내 정보
        </p>

        <ProfileSettingUserInfo/>
        <p
            style={{
              color: '#7E90A4',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '-0.42px',
              marginLeft: '16px',
              marginTop: '16px',
              marginBottom: '0px',
            }}
        >
          앱 설정
        </p>

        <div
            onClick={() => navigate('/mission-mate/notification-setting')}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '24px',
            }}
        >

          {/* 알림 */}
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
              }}
          >
            알림
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
              }}
          />
        </div>

        {/* 문의하기 */}
        <a
            href="mailto:threedaycommit@gmail.com?subject=미션프렌즈 문의&body=안녕하세요.%0D%0A아래 양식에 맞춰 문의 내용을 작성해 주시면 신속한 답변 도와드리겠습니다.%0D%0A%0D%0A[문의 양식]%0D%0A사용하고 계신 앱버전: %0D%0A문의 내용: %0D%0A%0D%0A(사진 이미지 캡처를 첨부해주시면 더 자세한 확인이 가능합니다.)"
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
            }}
        >
          <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
          >
            <p
                style={{
                  color: '#29333E',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-0.48px',
                  marginLeft: '16px',
                  marginTop: '0px',
                }}
            >
              문의하기
            </p>
            <img
                src={ic_right}
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '16px',
                }}
            />
          </div>
        </a>


        {/* 미션프렌즈 응원하기 */}
        <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
              }}
          >
            미션프렌즈 응원하기
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
              }}
          />
        </div>

        <p
            style={{
              color: '#7E90A4',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '-0.42px',
              marginLeft: '16px',
              marginTop: '24px',
              marginBottom: '0px',
            }}
        >
          앱 정보
        </p>

        {/* 정보 */}
        <div
            onClick={() => navigate('/mission-mate/setting-info')}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '24px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
          >
            정보
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
              }}
          />
        </div>


        {/* 미션프렌즈 응원하기 */}
        <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '24px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
              }}
          >
            버전 정보
          </p>

          <p
              style={{
                color: '#7E90A4',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                letterSpacing: '-0.42px',
                marginTop: '0px',
                marginBottom: '0px',
                marginRight: '16px',
              }}
          >
            1.0.0
          </p>
        </div>

        {/* 미션프렌즈 응원하기 */}
        <div
            onClick={() => setIsLogoutPopupOpen(true)}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
          >
            로그아웃
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
              }}
          />
        </div>


        {/* 미션프렌즈 응원하기 */}
        <div
            onClick={() => setDeleteUserPopupOpen(true)}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '24px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
          >
            계정 삭제하기
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
          />
        </div>

        <div>
          <PopupPage
              isOpen={isLogoutPopupOpen}
              onClose={() => setIsLogoutPopupOpen(false)}
              title="로그아웃 하시겠습니까?"
              onLeftClick={() => console.log('취소')}
              onRightClick={handleLogout}
              rightButtonText={'로그아웃'}
          />
        </div>

        <div>
          <PopupPage
              isOpen={deleteUserPopupOpen}
              onClose={() => setDeleteUserPopupOpen(false)}  // 수정된 부분
              title="계정을 삭제하시겠습니까?"
              onLeftClick={() => console.log('취소')}
              onRightClick={handleDeleteUser}
              rightButtonText={'삭제'}
          />
        </div>
      </div>
  )
}

export default UserSettingPage;
