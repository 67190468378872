import React from 'react';
import { useNavigate } from 'react-router-dom';
import ic_back from "../../../images/ic_back.png";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
      <div>

        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '21px',
              position: 'relative',
            }}
        >
          <img
              onClick={() => navigate("/mission-mate/setting-info")}
              src={ic_back}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '16px',
                position: 'absolute',
              }}
          />
          <p style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '26px',
            letterSpacing: '-0.54px',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}>
            개인정보 정책
          </p>
        </div>

        {/* Content */}
        <div style={{
          flex: 1,
          overflowY: 'auto',
          padding: '16px',
          fontFamily: 'Pretendard'
        }}>
          {/* 제1조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 1 조 (목적)</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              본 개인정보 처리방침은 미션 메이트(이하 "서비스")가 이용자의 개인정보를 어떻게 수집, 사용, 보호하는지를 설명합니다.
            </p>
          </section>


          {/* 제2조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 2 조 (수집하는 개인정보 항목)</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집할 수 있습니다.
            </p>

            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                필수 수집 항목: 이름, 이메일 주소, 휴대전화 번호, 계정 정보
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                선택 수집 항목: 프로필 사진, 친구 초대 내역, 미션 참여 기록
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                자동 수집 정보: 서비스 이용 기록, 기기 정보, IP 주소
              </li>
            </ol>
          </section>

          {/* 제3조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 3 조 (개인정보의 수집 및 이용 목적)</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              회사는 다음과 같은 목적을 위해 이용자의 개인정보를 수집 및 이용합니다.
            </p>

            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px',
              }}>
                서비스 제공 및 운영
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                이용자 식별 및 계정 관리
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                고객 지원 및 문의 응대
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                서비스 개선 및 맞춤형 콘텐츠 제공
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                법적 의무 준수
              </li>
            </ol>
          </section>

          {/* 제4조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 4 조 (개인정보의 보관 및 파기)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px',
              }}>
                회사는 이용자의 개인정보를 서비스 제공 기간 동안 보관하며, 이용 목적이 달성된 경우 즉시 파기합니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                관련 법령에 따라 일정 기간 보관이 필요한 경우 해당 법령을 준수합니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                파기 절차: 개인정보는 보관 기간이 경과하면 안전한 방법으로 즉시 삭제됩니다.
              </li>
            </ol>
          </section>

          {/* 제5조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 5 조 (개인정보의 제공 및 공유)</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              회사는 이용자의 개인정보를 원칙적으로 제3자에게 제공하지 않습니다. 단, 다음의 경우에는 예외로 합니다.
            </p>

            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px',
              }}>
                이용자가 사전에 동의한 경우
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                법률에 따라 요구되는 경우
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                서비스 제공을 위해 필요한 경우 (예: 결제 처리, 메시지 전송)
              </li>
            </ol>
          </section>


          {/* 제6조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 6 조 (개인정보 보호를 위한 안전조치)</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              회사는 이용자의 개인정보를 보호하기 위해 다음과 같은 조치를 취합니다.
            </p>

            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px',
              }}>
                데이터 암호화 및 보안 시스템 적용
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                접근 권한 제한 및 내부 교육 시행
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                개인정보 침해 방지를 위한 관리 조치
              </li>
            </ol>
          </section>

          {/* 제7조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 7 조 (이용자의 권리 및 행사 방법)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px',
              }}>
                이용자는 자신의 개인정보에 대한 열람, 수정, 삭제를 요청할 수 있습니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                개인정보 관련 문의는 고객센터를 통해 접수할 수 있으며, 회사는 신속하게 처리합니다.
              </li>
            </ol>
          </section>

          {/* 제8조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 8 조 (개인정보 처리방침의 변경)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px',
              }}>
                본 방침은 법률 개정 및 회사 정책 변경에 따라 변경될 수 있습니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                변경 사항은 서비스 내 공지를 통해 사전 안내합니다.
              </li>
            </ol>
          </section>

          {/* 부칙 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>부칙</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              본 약관은 2025년 1월 30일부터 시행됩니다.
            </p>
          </section>
        </div>
      </div>
  )
}

export default PrivacyPolicy;
