import React, {useState} from 'react';
import stamp_on from "../../../images/stamp_on.png";
import stamp_off from "../../../images/stamp_off.png";
import ic_right from "../../../images/ic_right.png";
import {useNavigate} from "react-router-dom";
import ic_up_btn from "../../../images/ic_up_btn.png";
import ic_down_btn from "../../../images/ic_down_btn.png";

const MissionListItem = ({missionId, title, memberNickName = [], daysInProgress, todayFinish, finished}) => {
  const navigate = useNavigate();

  const handleMissionDetail = () => {
    navigate("/mission-mate/mission-detail", {state: {missionId}});
  }

  return (
      <div
          onClick={handleMissionDetail}
          style={{
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            marginLeft: '16px',
            marginRight: '16px',
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '16px',
          }}
      >
        <img
            src={todayFinish ? stamp_on : stamp_off}
            style={{
              width: '96px',
              height: '96px',
              marginTop: '24px',
              marginBottom: '24px',
              marginLeft: '16px',
            }}
          />

        <div
            style={{
              display: 'flex',
              flexDirection: 'column', // 컴포넌트들이 세로로 배치되도록 설정
              justifyContent: 'flex-start',
              // marginLeft: '16px',
            }}
        >

          <div style={{
            backgroundColor: '#E5F2FC',
            borderRadius: '50px',
            alignSelf: 'flex-start',
            marginLeft: '16px',
            marginTop: '34px',
          }}>

          <p
              style={{
                color: '#227AF3',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '-0.36px',
                marginTop: '1px',
                marginBottom: '1px',
                padding: '4px 12px',
                marginLeft: '3px',
                marginRight: '3px',
              }}
          >
            {finished
                ? '미션 종료'
                : daysInProgress <= 0
                    ? '진행전'
                    : `${daysInProgress}일째 진행중`
            }
          </p>
          </div>

          <p
              style={{
                overflow: 'hidden',
                color: '#4A5B6C',
                textOverflow: 'ellipsis',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '8px',
                marginBottom: '0px',
              }}
          >
            {title}
          </p>

          <p
              style={{
                overflow: 'hidden',
                color: '#95A5B6',
                textOverflow: 'ellipsis',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '-0.36px',
                marginTop: '4px',
                marginLeft: '16px',
              }}
          >
            함께하는 프렌즈 : {memberNickName.join(', ')}
          </p>


        </div>

        <img
            src={ic_right}
            style={{
              width: '24px',
              height: '24px',
              marginTop: '16px',
              marginRight: '16px',
              marginLeft: 'auto'  // 이 부분 추가
        }}
        />
      </div>
  )
}

export default MissionListItem;
