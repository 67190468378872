import React, {useEffect, useState} from 'react';
import ic_back from "../../../images/ic_back.png";
import check_icon_unselected from "../../../images/check_icon_unselected.png";
import check_icon_selected from "../../../images/check_icon_selected.png";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {findAll} from "../../../api/MissionListPageApi";
import {
  findMemberNotificationInfo, updateMemberNotification,
  updateMissionNotification
} from "../../../api/NotificationSettingPageApi";

const NotificationSettingPage = () => {
  const location = useLocation();
  const missionId = location.state?.missionId;

  const [marketingToggle, setMarketingToggle] = useState(false);
  const [friendsToggle, setFriendsToggle] = useState(true);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const [missions, setMissions] = useState([]);
  const [notificationAllow, setNotificationAllow] = useState(false);

  const handleBack = () => {
    navigate('/mission-mate/profile-setting');
  };

  const handleNotificationToggle = async (missionId, currentState) => {
    try {
      await updateMissionNotification(missionId, !currentState, token,
          navigate); // API 함수 필요
      setMissions(missions.map(mission =>
          mission.missionId === missionId
              ? {...mission, notificationAllow: !mission.notificationAllow}
              : mission
      ));
    } catch (error) {
      console.error('알림 상태 업데이트 실패:', error);
    }
  };

  const handleFriendsToggle = async () => {
    try {
      // POST /member-notifications/update API 호출
      await updateMemberNotification(!notificationAllow, token, navigate);
      setNotificationAllow(!notificationAllow);

      // 토글이 꺼질 때는 미션 목록을 비움
      if (!notificationAllow) {
        const response = await findAll(token);
        setMissions(response.missionListResponses);
      } else {
        setMissions([]);
      }
    } catch (error) {
      console.error('알림 상태 업데이트 실패:', error);
    }
  };

  useEffect(() => {
    const loadNotificationInfo = async () => {
      try {
        const response = await findMemberNotificationInfo(token, navigate);
        console.log('알림 정보 로드 성공:', response.notificationAllow);
        setNotificationAllow(response.notificationAllow);

        if (response.notificationAllow) {
          const missionsResponse = await findAll(token, navigate);
          setMissions(missionsResponse.missionListResponses);
        }
      } catch (error) {
        console.error('알림 정보 로드 실패:', error);
      }
    };

    loadNotificationInfo();
  }, [token]); // notificationAllow 제거

  return (
      <div style={{}}>
        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '21px',
              position: 'relative',
            }}
        >
          <img
              src={ic_back}
              onClick={handleBack}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '16px',
                marginRight: '16px',
                position: 'absolute',
                cursor: 'pointer',
              }}
          />
          <p style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '26px',
            letterSpacing: '-0.54px',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}>
            알림
          </p>
        </div>

        <div>

          <div style={{}}>

            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '23px',
              marginLeft: '16px',
            }}>
              <span style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
              }}>마케팅 정보 알림</span>


              <button
                  style={{
                    width: '56px',
                    height: '32px',
                    position: 'relative',
                    padding: 0,
                    border: 'none',
                    outline: 'none',            // 클릭 시 테두리 제거
                    backgroundColor: 'transparent', // 배경색 투명하게
                    WebkitTapHighlightColor: 'transparent',  // 모바일에서 터치 시 하이라이트 효과 제거
                    WebkitAppearance: 'none',   // 기본 버튼 스타일 제거
                    marginRight: '16px',
                  }}
                  onClick={() => setMarketingToggle(!marketingToggle)}
              >
                <svg
                    width="56"
                    height="32"
                    viewBox="0 0 56 32"
                    fill="none"
                    style={{
                      transition: 'all 0.3s ease'
                    }}
                >
                  // 마케팅 정보 알림 토글 부분
                  <rect width="56" height="32" rx="16"
                        fill={marketingToggle ? "#4081FF" : "#95A5B6"}/>
                  <circle
                      cx={marketingToggle ? "40" : "16"}
                      cy="16"
                      r="13"
                      fill="white"
                      style={{
                        transition: 'cx 0.3s ease'
                      }}
                  />
                </svg>
              </button>
            </div>


            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '16px',
              marginLeft: '16px',
            }}>
                <span style={{
                  color: '#29333E',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-0.48px',
                }}>프렌즈 미션 달성 알림</span>

              <button
                  style={{
                    width: '56px',
                    height: '32px',
                    position: 'relative',
                    padding: 0,
                    border: 'none',
                    outline: 'none',
                    backgroundColor: 'transparent',
                    WebkitTapHighlightColor: 'transparent',
                    WebkitAppearance: 'none',
                    marginRight: '16px',

                  }}
                  onClick={handleFriendsToggle}
              >
                <svg
                    width="56"
                    height="32"
                    viewBox="0 0 56 32"
                    fill="none"
                    style={{
                      transition: 'all 0.3s ease'
                    }}
                >
                  // 프렌즈 미션 달성 알림 토글 부분
                  <rect width="56" height="32" rx="16"
                        fill={notificationAllow ? "#4081FF" : "#95A5B6"}/>
                  <circle
                      cx={notificationAllow ? "40" : "16"}
                      cy="16"
                      r="13"
                      fill="white"
                      style={{
                        transition: 'cx 0.3s ease'
                      }}
                  />
                </svg>
              </button>
            </div>
          </div>

          {notificationAllow && (
              <div
                  style={{
                    marginTop: '14px',
                  }}
              >
                {missions.map((mission) => (
                    <div key={mission.missionId} style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '16px',
                    }}
                         onClick={() => handleNotificationToggle(
                             mission.missionId,
                             mission.notificationAllow)}
                    >
                              <span style={{
                                color: '#5A6F83',
                                fontFamily: 'Pretendard',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '20px',
                                letterSpacing: '-0.42px'
                              }}>
                                {mission.title}
                              </span>
                      <img
                          src={mission.notificationAllow ? check_icon_selected
                              : check_icon_unselected} // 이미지 import 필요
                          alt="notification toggle"
                          style={{
                            width: '24px',
                            height: '24px',
                            cursor: 'pointer'
                          }}
                      />
                    </div>
                ))}
              </div>
          )}


        </div>
      </div>
  );
};

export default NotificationSettingPage;
