import React, { useEffect } from 'react';

const ToastAlert = ({ message, isVisible, setIsVisible }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, setIsVisible]);

  if (!isVisible) return null;

  return (
      <div
          style={{
            position: 'fixed',
            bottom: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'calc(100% - 40px)',
            maxWidth: '335px',
            display: 'flex',
            alignItems: 'center', // 세로 중앙 정렬
            justifyContent: 'center', // 가로 중앙 정렬
            zIndex: 1000,
            backgroundColor: '#161616',
            borderRadius: '6px',
            opacity: 0.8,
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
      >
        <p
            style={{
              color: '#FCFCFD',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.42px',
              margin: 0,
            }}
        >
          {message}
        </p>
      </div>
  );
};

export default ToastAlert;
