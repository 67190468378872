import React, {useEffect, useState} from 'react';
import homeIndicator from "../../../images/Home Indicator.png";
import {
  findAll,
  create,
  update
} from "../../../api/MissionRecordBottomSheetApi";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const MissionCreateRecordBottomSheet = ({
  missionId,
  missionRecordId,
  content,
  onClose,
  onRecordUpdate
}) => {
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [selectedStickerImageUrl, setSelectedStickerImageUrl] = useState('');
  const [memo, setMemo] = useState(content || ''); // content가 있으면 초기값으로 설정
  const [isClosing, setIsClosing] = useState(false);
  const [stickers, setStickers] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await findAll();
        setStickers(response.stickerImageResponses);
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 300);
  };

  const handleStickerClick = (stickerId) => {
    setSelectedSticker(selectedSticker === stickerId ? null : stickerId);
    setSelectedStickerImageUrl(
        stickers.find((sticker) => sticker.id === stickerId).imageUrl);
  };

  const handleSave = async () => {
    if (!selectedSticker) {
      return;
    }
    if (missionRecordId == null) {
      // 여기를 수정
      await create(missionId, memo, selectedStickerImageUrl, token, navigate);
      handleClose();
      onRecordUpdate?.();  // window.location.reload() 대신 이것으로 교체
      return;
    }
    await update(missionId, missionRecordId, memo, selectedStickerImageUrl,
        token, navigate);
    handleClose();
    onRecordUpdate?.();
  };

  return (
      <>
        {/* 오버레이 */}
        <div
            onClick={handleClose}
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              transition: 'opacity 300ms',
              zIndex: 999
            }}
        />


        {/* 바텀 시트 */}
        <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              transform: 'translateY(0)',
              backgroundColor: '#FFFFFF',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              zIndex: 1000,
              animation: isClosing ? 'slideDown 300ms ease-out forwards'
                  : 'slideUp 300ms ease-out forwards'
            }}
        >

          {/* Home Indicator */}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 0'
          }}>
            <img
                src={homeIndicator}
                style={{
                  width: "64px",
                  height: "5px",
                }}
                alt="indicator"
            />
          </div>

          {/* 헤더 */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '30px',
            marginLeft: '16px',
            marginRight: '16px'
          }}>
            <div
                onClick={handleClose}
                style={{
                  width: '24px',
                  height: '24px',
                }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 18L9 12L15 6" stroke="#29333E" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>

            <span style={{
              color: '#29333E',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '26px',
              letterSpacing: '-0.54px'
            }}>
            미션 달성
          </span>

            <span
                onClick={handleSave}
                style={{
                  color: selectedSticker ? '#4A5B6C' : '#B2BFCC',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-0.48px',
                  cursor: 'pointer'
                }}
            >
            저장
          </span>
          </div>

          {/* 스티커 선택 텍스트 */}
          <div style={{
            marginTop: '24px',
            marginLeft: '16px',
          }}>
          <span style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '-0.48px'
          }}>
            스티커 선택
          </span>
          </div>

          {/* 스티커 그리드 */}
          <div style={{
            marginLeft: '32px',
            marginRight: '32px',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 88px)',
            gap: '16px',
            justifyContent: 'center',
            marginTop: '16px'
          }}>
            {stickers.map((sticker) => (
                <div
                    key={sticker.id}
                    onClick={() => handleStickerClick(sticker.id)}
                    style={{
                      width: '88px',
                      height: '88px',
                      border: selectedSticker === sticker.id
                          ? '2px solid #227AF3' : 'none',
                      borderRadius: '50%'
                    }}
                >
                  <img
                      src={sticker.imageUrl}
                      alt={`sticker-${sticker.id}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '50%'
                      }}
                  />
                </div>
            ))}
          </div>


          {/* 메모 입력창 */}
          <div style={{
            marginTop: '24px',
            paddingBottom: '48px',
            backgroundColor: '#EBEFF6',
          }}>

            <p
                style={{
                  color: '#29333E',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-0.48px',
                  marginLeft: '16px',
                  paddingTop: '24px',
                  marginBottom: '0px',
                }}

            >
              한 줄 메모
            </p>
            <div style={{
              display: 'flex',
              minHeight: '48px',            // 다시 고정 높이로
              alignItems: 'center',      // 중앙 정렬
              borderRadius: '8px',
              border: '1px solid #CFD9E2',
              backgroundColor: '#FFFFFF',
              marginLeft: '16px',
              marginRight: '16px',
              marginTop: '12px',
            }}>
           <textarea
               value={memo}
               onChange={(e) => setMemo(e.target.value)}
               placeholder="한 줄 메모를 입력해 주세요. (선택)"
               style={{
                 width: '100%',
                 border: 'none',
                 outline: 'none',
                 color: '#29333E',
                 fontFamily: 'Pretendard',
                 fontSize: '14px',
                 fontWeight: 500,
                 lineHeight: '20px',
                 letterSpacing: '-0.42px',
                 marginLeft: '12px',
                 resize: 'none',
                 display: 'flex',
                 alignItems: 'center',
                 minHeight: '20px',      // 최소 높이 설정
                 height: '20px',         // 고정 높이 설정
                 maxHeight: '80px',      // 3줄까지 (20px * 3)
                 overflow: 'auto',       // 스크롤바
               }}
           />
            </div>
          </div>


          <style jsx>{`
            textarea::placeholder {
              color: #B2BFCC;
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.42px;
            }


            @keyframes slideUp {
              from {
                transform: translateY(100%);
              }
              to {
                transform: translateY(0);
              }
            }

            @keyframes slideDown {
              from {
                transform: translateY(0);
              }
              to {
                transform: translateY(100%);
              }
            }
          `}</style>
        </div>
      </>
  );
};

export default MissionCreateRecordBottomSheet;
