import React, {useEffect, useState} from 'react';
import ic_back from "../../images/ic_back.png";
import ProfileSetupPage from "./ProfileImagesSelectedPage";
import NickNameInput from "./NickNameInput";
import GenderInput from "./GenderInput";
import BirthdayInput from "./BirthdayInput";
import {getMemberInfo, update} from "../../api/ProfileSetupPageApi";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const ProfileSetupPreview = () => {
  const location = useLocation();
  const isLandingProfileSetting = location.state?.isLandingProfileSetting;
  const [nickname, setNickname] = useState(""); // 닉네임 상태 관리
  const [gender, setGender] = useState(""); // 성별 상태 관리
  const [birthday, setBirthday] = useState(""); // 생년월일 상태 관리
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [profileImageUrl, setProfileImageUrl] = useState("");

  const handleUpdate = async () => {
    await update(nickname, gender, birthday, profileImageUrl, token, navigate);
    navigate('/mission-mate/mission-list');
  }

  useEffect(() => {
    if (isLandingProfileSetting) {
      const loadData = async () => {
        try {
          const response = await getMemberInfo(token);
          setNickname(response.nickname);
          setGender(response.gender);
          setBirthday(response.birth);
          setProfileImageUrl(response.imageUrl);
        } catch (error) {
          console.error('데이터 로드 실패:', error);
        }
      };
      loadData();
    }
  }, []);

  return (
      <div>

        {isLandingProfileSetting ? (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '30px',
              marginLeft: '16px',
              marginRight: '16px'
            }}>
              <div
                  onClick={() => navigate('/mission-mate/profile-setting')}
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M15 18L9 12L15 6" stroke="#29333E" strokeWidth="2"
                        strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>

              <span style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '26px',
                letterSpacing: '-0.54px'
              }}>
            프로필
          </span>

              <span
                  onClick={handleUpdate}
                  style={{
                    color: '#4A5B6C',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.48px',
                    cursor: 'pointer'
                  }}
              >
            저장
          </span>
            </div>
        ) : (<div
                style={
                  {
                    display: 'flex',
                    height: '58px',
                  }
                }
            >
              <img
                  src={ic_back}
                  style={{
                    width: '24px',
                    height: '24px',
                    marginTop: '17px',
                    marginLeft: '16px',
                  }}
              />

              <p
                  style={{
                    color: '#29333E',
                    textAlign: 'center',
                    position: 'absolute',  // 추가
                    width: '100%',        // 추가
                    margin: 0,           // 추가
                    fontFamily: 'Pretendard',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '26px',
                    letterSpacing: '-0.54px',
                    marginTop: '17px',
                  }}>
                프로필 등록
              </p>
            </div>
        )}

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>


          <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                padding: '20px',
                borderRadius: '100px',
                background: '#C0DFF8',
                marginTop: '35px',
              }}
          >
            <img
                src={profileImageUrl}
                style={{
                  width: '80px',
                  height: '80px',
                }}
            />
          </div>
        </div>


        <div
            style={
              {
                marginLeft: '16px',
                display: 'flex',
                justifyContent: 'center',
              }
            }>
          <ProfileSetupPage setProfileUrl={setProfileImageUrl}/>
        </div>
        <NickNameInput
            onChange={setNickname}
            defaultNickname={nickname}
        />
        <GenderInput
            onChange={setGender}
            defaultGender={gender} // gender 상태값을 defaultGender로 전달
        />
        <BirthdayInput
            onChange={setBirthday}
            defaultBirth={birthday}
        />


        {!isLandingProfileSetting && (
            <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
            >
              <button
                  onClick={handleUpdate}
                  style={{
                    width: '100%',
                    height: '48px',
                    backgroundColor: '#227AF3',
                    color: 'white',
                    border: 'none',
                    marginTop: '120px',
                    marginLeft: '16px',
                    marginRight: '16px',
                    borderRadius: '8px',
                    marginBottom: '48px',
                  }}
              >
                시작하기
              </button>
            </div>
        )}
      </div>
  )
};

export default ProfileSetupPreview;
