import axios from "axios";
import {url} from "./ApiConstant";

export async function updateMissionNotification(
    missionId,
    notificationAllow,
    token,
    navigate) {
  try {
    const response = await axios.post(
        `${url}/mission-member-notifications/update`,
        {
          notificationAllow,
          missionId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}

export async function findMemberNotificationInfo(token, navigate) {
  try {
    const response = await axios.get(
        `${url}/member-notifications`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', { replace: true }); // 로그인 페이지로 리디렉션
    }
  }
}

export async function updateMemberNotification(
    notificationAllow,
    token,
    navigate) {
  try {
    const response = await axios.post(
        `${url}/member-notifications/update`,
        {
          notificationAllow,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}
