import axios from 'axios';
import {url} from './ApiConstant';

export async function create(
    title,
    startDate,
    endDate,
    days, token, navigate) {
  try {
    const response = await axios.post(
        `${url}/missions`,
        { title,
          startDate,
          endDate,
          days},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
    if (error.response?.status === 400) {
      return error;
    }
  }
}


export async function update(
    missionId,
    title,
    startDate,
    endDate,
    days, token, navigate) {
  try {
    const response = await axios.post(
        `${url}/missions/${missionId}`,
        { title,
          startDate,
          endDate,
          days},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}
