import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setFirebaseToken} from "../redux/UserStore";
import {useNavigate} from "react-router-dom";
import {existProfileImage} from "../api/CommerLoginApi";
import {saveFcmToken} from "../api/FcmRegisterApi";

const Main = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const firebaseToken = new URL(window.location.href).searchParams.get("firebaseToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const checkNickName = async () => {
      try {
        const firebaseToken = new URL(window.location.href).searchParams.get("firebaseToken");
        if (firebaseToken !== null) {
          dispatch(setFirebaseToken(firebaseToken));
        }

        if (!token) {
          navigate('/mission-mate/login');
        }

        const response = await existProfileImage(token, navigate)
        if (response.profileImageUrl === false) {
          navigate("/mission-mate/profile-setup");
          return;
        }
        navigate("/mission-mate/mission-list");
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    }
    checkNickName();
  }, [navigate, dispatch, firebaseToken]);

  return (
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        {/*<h1>메인 페이지 {token}</h1>*/}
        {/*<h1>firebaseToken {firebaseToken}</h1>*/}
      </div>
  );
};

export default Main;
