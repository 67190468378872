import axios from 'axios';
import {url} from './ApiConstant';

export async function createInviteUrl(missionId, token, navigate) {
  try {
    const response = await axios.post(
        `${url}/invite/missions/${missionId}`,
        {
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}

export async function getInvitationInfoByToken(
    inviteToken,
    token,
    navigate
) {
  try {
    const response = await axios.post(
        `${url}/invite/token`,
        {
          inviteToken
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}

