import React, { useState, useEffect } from 'react';
import ic_down from "../../../../images/ic__down.png";
import MissionUpdateDateBottomSheet from "./MissionUpdateDateBottomSheet";

const MissionUpdateDate = ({ onSubmit, initialStartDate, initialEndDate, initialSelectedDays = [] }) => {
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [activeInput, setActiveInput] = useState(null);

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  const days = ['월', '화', '수', '목', '금', '토', '일'];

  const convertKoreanDayToEnum = (koreanDay) => {
    const dayMap = {
      'MONDAY': '월',
      'TUESDAY': '화',
      'WEDNESDAY': '수',
      'THURSDAY': '목',
      'FRIDAY': '금',
      'SATURDAY': '토',
      'SUNDAY': '일'
    };

    return dayMap[koreanDay];
  };

  useEffect(() => {
    setSelectedStartDate(initialStartDate);
    setSelectedEndDate(initialEndDate);
    setSelectedDays(initialSelectedDays.map(day => convertKoreanDayToEnum(day)));
  }, [initialStartDate, initialEndDate, initialSelectedDays]);

  const handleDayClick = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const formatDate = (dateStr) => {
    if (!dateStr) {
      return '';
    }

    const date = new Date(dateStr);
    const year = date.getFullYear().toString().slice(2);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}년${month}월${day}일`;
  };

  const handleInputClick = (inputType) => {
    setActiveInput(inputType);
    setShowBottomSheet(true);
  };

  const handleStartSubmit = () => {
    if (selectedStartDate && selectedEndDate && selectedDays.length > 0) {
      onSubmit({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        selectedDays
      });
    }
  };

  const isValid = selectedStartDate && selectedEndDate && selectedDays.length > 0;

  return (
      <div>
        <div style={{
          marginTop: '32px',
          marginLeft: '16px',
          marginBottom: '0px',
        }}>
        <span style={{
          color: '#3B4856',
          fontFamily: 'Pretendard',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '32px',
          letterSpacing: '-0.72px',
        }}>미션의 </span>
          <span style={{
            color: '#227AF3',
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '32px',
            letterSpacing: '-0.72px',
          }}>기간과 인증<br/> 요일을 설정</span>
          <span style={{
            color: '#3B4856',
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '32px',
            letterSpacing: '-0.72px',
          }}>해 주세요.</span>
        </div>

        <p style={{
          color: '#4A5B6C',
          fontFamily: 'Pretendard',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.48px',
          marginTop: '40px',
          marginLeft: '16px',
        }}>미션 기간</p>

        {/* Date Input Container */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          margin: '16px',
          gap: '8px',
          marginBottom: '0px',
        }}>
          <div
              onClick={() => handleInputClick('start')}
              style={{
                flex: 1,
                backgroundColor: 'white',
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#B2BFCC',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '12px 0',  // 상하 패딩 추가
              }}
          >
            {selectedStartDate ?
                <p
                    style={{
                      color: '#4A5B6C',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '-0.42px',
                      marginLeft: '16px',
                      marginTop: '0px',
                      marginBottom: '0px',
                    }}
                >
                  {formatDate(selectedStartDate)}
                </p>
                : <p
                    className="mission-input"
                    style={{
                      color: '#B2BFCC',
                      textAlign: 'start',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '-0.42px',
                      marginLeft: '16px',
                      marginTop: '0px',
                      marginBottom: '0px',
                    }}
                >
                  시작일
                </p>
            }

            <img
                src={ic_down}
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '16px',
                }}
                alt="down arrow"
            />
          </div>
          <span style={{color: '#4A5B6C'}}>~</span>
          <div
              onClick={() => handleInputClick('end')}
              style={{
                flex: 1,
                backgroundColor: 'white',
                borderRadius: '8px',
                borderStyle: 'solid',
                borderColor: '#B2BFCC',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderWidth: '1px',
                padding: '12px 0',  // 상하 패딩 추가
              }}
          >
            {selectedEndDate ?
                <p
                    style={{
                      color: '#4A5B6C',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '-0.42px',
                      marginLeft: '16px',
                      marginTop: '0px',
                      marginBottom: '0px',
                    }}
                >
                  {formatDate(selectedEndDate)}
                </p>
                : <p
                    className="mission-input"
                    style={{
                      color: '#B2BFCC',
                      textAlign: 'start',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '-0.42px',
                      marginLeft: '16px',
                      marginTop: '0px',
                      marginBottom: '0px',
                    }}
                >
                  마감일
                </p>
            }

            <img
                src={ic_down}
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '16px',
                }}
                alt="down arrow"
            />
          </div>
        </div>

        <p style={{
          color: '#4A5B6C',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '-0.42px',
          marginTop: '8px',
          marginLeft: '16px',
        }}>
          내일부터 설정할 수 있어요.
        </p>

        <p style={{
          color: '#4A5B6C',
          fontFamily: 'Pretendard',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.48px',
          marginTop: '32px',
          marginLeft: '16px',
        }}>미션 인증 요일</p>

        {/* Days Selection */}
        <div style={{
          display: 'flex',
          margin: '16px',
          gap: '12px'
        }}>
          {days.map((day) => (
              <div
                  key={day}
                  onClick={() => handleDayClick(day)}
                  style={{
                    flex: 1,
                    padding: '10px 12px',
                    backgroundColor: selectedDays.includes(day) ? '#227AF3' : '#EBEFF6',
                    color: selectedDays.includes(day) ? '#FFFFFF' : '#4A5B6C',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    transition: 'background-color 0.3s',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
              >
                {day}
              </div>
          ))}
        </div>

        {/* Start Button */}
        <div
            onClick={isValid ? handleStartSubmit : undefined}
            style={{
              margin: '16px',
              padding: '16px',
              backgroundColor: isValid ? '#227AF3' : '#CFD9E2',
              color: 'white',
              borderRadius: '8px',
              textAlign: 'center',
              cursor: isValid ? 'pointer' : 'default',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontWeight: 600,
            }}
        >
          시작하기
        </div>

        {/* Bottom Sheet */}
        {showBottomSheet && (
            <MissionUpdateDateBottomSheet
                activeInput={activeInput}
                setActiveInput={setActiveInput}
                setShowBottomSheet={setShowBottomSheet}
                showBottomSheet={showBottomSheet}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
            />
        )}
      </div>
  );
};

export default MissionUpdateDate;
