import React from "react";
import empty_mission_img from "../../../images/empty_mission_img.png";

const EmptyMissionList = () => {
  return (
      <div>
        <div
            style={{
              display: 'flex',
              marginTop: '100px',
            }}
        >
          <img
              src={empty_mission_img}
              style={{
                width: '128px',
                height: '128px',
                flexShrink: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
          />
        </div>
        <div
            style={{
              display: 'flex',
              marginTop: '16px',
            }}
        >
           <span
               style={
                 {
                   color: '#4A5B6C',
                   textAlign: 'center',
                   fontFamily: 'Pretendard',
                   fontSize: '16px',
                   fontStyle: 'normal',
                   fontWeight: 500,
                   lineHeight: '24px',
                   letterSpacing: '-0.48px',
                   marginLeft: 'auto',
                   marginRight: 'auto',
                 }
               }
           >
            진행중인 미션이 없어요.
          </span>

        </div>
        <div
            style={{
              display: 'flex',
            }}
        >
          <span
              style={
                {
                  color: '#4A5B6C',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-0.48px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }
              }
          >
            지금 바로 미션을 만들어 보세요!
          </span>
        </div>
      </div>
  )
}

export default EmptyMissionList;
