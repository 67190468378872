import axios from 'axios';
import {url} from './ApiConstant';

export async function postGuestLogin() {
  try {
    const response = await axios.post(`${url}/auth/guest/login`, );
    return response.data;
  } catch (error) {
    console.error('카카오 로그인 요청 실패:', error);
    throw error;
  }
}
