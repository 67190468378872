import React, {useState} from 'react';
import MissionRecordCreateBottomSheet from './MissionRecordCreateBottomSheet';
import MissionRecordUpdateBottomSheet from "./MissionRecordUpdateBottomSheet";

const MissionDetailDateItem = ({
  id,
  missionId,
  missionRecordId,
  content,
  today,
  imageUrl,
  memberId,
  requestMemberId,
  onRecordUpdate  // 이 prop 추가
}) => {
  const [isMissionRecordCreateBottomSheetOpen, setIsMissionRecordCreateBottomSheetOpen] = useState(
      false);
  const [isMissionRecordUpdateBottomSheetOpen, setIsMissionRecordUpdateBottomSheetOpen] = useState(
      false);

  const handleClick = () => {
    if (imageUrl !== null) {
      setIsMissionRecordUpdateBottomSheetOpen(true);
      return
    }
    if (today) {
      setIsMissionRecordCreateBottomSheetOpen(true);
    }
  };

  return (
      <>
        <div
            onClick={handleClick}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '24px',
              marginTop: '0px',
              marginBottom: '8px',
              padding: '0',
              backgroundColor: today ? '#C0DFF8' : '#EBEFF6',
              marginLeft: '0px',
              marginRight: '0px',
              aspectRatio: '1',
              width: '48px',
              cursor: today ? 'pointer' : 'default',
            }}
        >
          {imageUrl ? (
              <img
                  src={imageUrl}
                  alt="Sticker"
                  style={{
                    width: '48px',
                    height: '48px',
                  }}
              />
          ) : (
              <p
                  style={{
                    color: today ? '#4C9AE8' : '#CFD9E2',
                    textAlign: 'center',
                    fontFamily: 'CookieRunOTF',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '-0.72px',
                    marginTop: '12px',
                    marginBottom: '12px',
                    marginRight: '0px',
                    marginLeft: '0px',
                  }}
              >
                {id}
              </p>)}
        </div>

        {(isMissionRecordCreateBottomSheetOpen && memberId === requestMemberId) && (
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999
              // pointerEvents: 'none' 제거
            }}>
              <MissionRecordCreateBottomSheet
                  missionId={missionId}
                  missionRecordId={missionRecordId}
                  content={content} // content 추가
                  onClose={() => setIsMissionRecordCreateBottomSheetOpen(false)}
                  onRecordUpdate={onRecordUpdate}  // 이 prop 추가
              />
            </div>
        )}


        {(isMissionRecordUpdateBottomSheetOpen && memberId === requestMemberId) && (
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999
              // pointerEvents: 'none' 제거
            }}>
              <MissionRecordUpdateBottomSheet
                  missionId={missionId}
                  missionRecordId={missionRecordId}
                  content={content}
                  onClose={() => setIsMissionRecordUpdateBottomSheetOpen(false)}
                  setIsMissionRecordCreateBottomSheetOpen={setIsMissionRecordCreateBottomSheetOpen}
              />
            </div>
        )}

      </>
  );
};

export default MissionDetailDateItem;
