import React, {useEffect, useState} from 'react';
import homeIndicator from "../../../images/Home Indicator.png";
import stamp_on from "../../../images/stamp_on.png";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {
  isOwnerMissionRecord
} from "../../../api/MissionRecordBottomSheetApi";

const MissionRecordUpdateBottomSheet = ({
  missionId,
  missionRecordId,
  content,
  onClose,
  setIsMissionRecordCreateBottomSheetOpen
}) => {
  const [memo, setMemo] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [stickers, setStickers] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const handleClickUpdate = () => {
    // 먼저 현재 시트를 닫고
    setIsClosing(true);
    // 애니메이션이 완료된 후 새 시트를 열도록 타이밍 조정
    setTimeout(() => {
      onClose(); // 현재 시트를 완전히 닫고
      setIsMissionRecordCreateBottomSheetOpen(true); // 그 다음 새 시트 열기
    }, 300); // slideDown 애니메이션 시간과 동일하게 맞춤
    setIsMissionRecordCreateBottomSheetOpen(true)
    setIsClosing(true)
  }


  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await isOwnerMissionRecord(missionRecordId, token, navigate);
        setIsOwner(response);
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 300);
  };

  return (
      <>
        {/* 오버레이 */}
        <div
            onClick={handleClose}
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              transition: 'opacity 300ms',
              zIndex: 999
            }}
        />


        {/* 바텀 시트 */}
        <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              transform: 'translateY(0)',
              backgroundColor: '#FFFFFF',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              zIndex: 1000,
              animation: isClosing ? 'slideDown 300ms ease-out forwards'
                  : 'slideUp 300ms ease-out forwards'
            }}
        >

          {/* Home Indicator */}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 0'
          }}>
            <img
                src={homeIndicator}
                style={{
                  width: "64px",
                  height: "5px",
                }}
                alt="indicator"
            />
          </div>

          {/* 헤더 */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '30px',
            marginLeft: '16px',
            marginRight: '16px'
          }}>
            <div
                onClick={handleClose}
                style={{
                  width: '24px',
                  height: '24px',
                }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 18L9 12L15 6" stroke="#29333E" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>

            <span style={{
              color: '#29333E',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '26px',
              letterSpacing: '-0.54px'
            }}>
            미션 달성
          </span>
            {(isOwner) && (
                <span
                    onClick={handleClickUpdate}
                    style={{
                      color: '#4A5B6C',
                      fontFamily: 'Pretendard',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '-0.48px',
                      cursor: 'pointer'
                    }}
                >
            편집
          </span>
            )}
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '35px',
            marginBottom: '0px'
          }}>
            <img
                src={stamp_on}
                style={{
                  width: '96px',
                  height: '96px',
                }}
            />
          </div>
          <p
              style={{
                color: '#227AF3',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginTop: '16px',
              }}
          >
            미션을 달성했어요!
          </p>

          <div
              style={{
                display: 'flex',
                marginLeft: '16px',
                marginRight: '16px',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: '8px',
                background: 'var(--Grayscale-Gray_10, #EBEFF6)',
                marginBottom: '48px',
              }}
          >
            <p
                style={{
                  color: '#4A5B6C',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.42px',
                  marginLeft: '12px',
                  marginRight: '12px',
                  marginTop: '14px',
                  marginBottom: '14px',
                }}
            >
              {content || '기록한 내용이 없습니다.'}
            </p>
          </div>


          <style jsx>{`
            textarea::placeholder {
              color: #B2BFCC;
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.42px;
            }


            @keyframes slideUp {
              from {
                transform: translateY(100%);
              }
              to {
                transform: translateY(0);
              }
            }

            @keyframes slideDown {
              from {
                transform: translateY(0);
              }
              to {
                transform: translateY(100%);
              }
            }
          `}</style>
        </div>
      </>
  )
}

export default MissionRecordUpdateBottomSheet;
