import React from 'react';
import KakaoLoginImage from "../../images/btn_kakao_login.png";


const REDIRECT_URI = "https://one-daily-app.shop/mission-mate/auth/login";
// const REDIRECT_URI = "http://localhost:3000/mission-mate/auth/login";

const KakaoLoginButton = () => {
  return (
      <div>
        <a        // <-- 여기 a 태그 추가
            href={`https://kauth.kakao.com/oauth/authorize?client_id=930e6a96434fe5278b3467525497deb9&redirect_uri=${REDIRECT_URI}&response_type=code`}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '328px',
              height: '52px',
              marginBottom: '16px',
              padding: '0',
            }}
        >
          <img
              src={KakaoLoginImage}
              alt="카카오 로그인"
              style={{ width: '100%', height: '100%' }}
          />
        </a>
      </div>
  );
};

export default KakaoLoginButton;
