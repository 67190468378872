import React, {useEffect, useState} from "react";
import homeIndicator from "../../images/Home Indicator.png";

const GenderBottomSheet = ({isOpen, onClose, onSelect, defaultValue}) => {
  const [tempSelectedGender, setTempSelectedGender] = useState(null); // 임시 선택 상태
  const [selectedGender, setSelectedGender] = useState(null); // 확정 선택 상태

  // bottomSheet가 열릴 때 defaultValue가 있으면 적용
  useEffect(() => {
    if (isOpen && defaultValue) {
      setTempSelectedGender(defaultValue);
    }
  }, [isOpen, defaultValue]);

  // bottomSheet가 닫힐 때 tempSelectedGender 초기화
  useEffect(() => {
    if (!isOpen) {
      setTempSelectedGender(null);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
      <>
        <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(22, 22, 22, 0.80)",
              zIndex: 999
            }}
            onClick={onClose}
        />

        <div
            style={{
              padding: "20px 16px",
              backgroundColor: "#FFFFFF",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.1)",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
            }}
        >
          {/* 홈 인디케이터 */}
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
          >
            <img
                src={homeIndicator}
                style={{
                  width: "64px",
                  height: "5px",
                }}
                alt="indicator"
            />
          </div>

          {/* 타이틀 */}
          <p
              style={{
                color: "#29333E",
                fontFamily: "Pretendard",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "26px",
                letterSpacing: "-0.54px",
                margin: 0,
              }}
          >
            성별
          </p>

          {/* 버튼 컨테이너 */}
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
          >
            {/* 남자 버튼 */}
            <p
                onClick={() => setTempSelectedGender("남자")} // 임시 선택
                style={{
                  flex: 1, // 버튼들이 화면을 꽉 채우도록 설정
                  height: "48px",
                  borderRadius: "8px 0px 0px 8px",
                  background: tempSelectedGender === "남자" ? "#E5F2FC" : "white",
                  border:
                      tempSelectedGender === "남자"
                          ? "0.5px solid #E5F2FC" // 선택된 항목은 테두리 제거
                          : "0.5px solid #E5F2FC", // 선택되지 않은 항목만 테두리 유지
                  color: tempSelectedGender === "남자"
                      ? "#227AF3"
                      : "#4A5B6C",
                  fontFamily: "Pretendard",
                  fontSize: "15px",
                  fontWeight: 500,
                  lineHeight: "22px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  letterSpacing: "-0.45px",
                  cursor: "pointer",
                }}
            >
              남자
            </p>

            {/* 여자 버튼 */}
            <p
                onClick={() => setTempSelectedGender("여자")} // 임시 선택
                style={{
                  flex: 1, // 버튼들이 화면을 꽉 채우도록 설정
                  height: "48px",
                  borderRadius: "0 8px 8px 0px",
                  background: tempSelectedGender === "여자" ? "#E5F2FC" : "white",
                  border: tempSelectedGender === "여자"
                      ? "0.5px solid #E5F2FC" // 선택된 항목은 테두리 제거
                      : "0.5px solid #E5F2FC", // 선택되지 않은 항목만 테두리 유지
                  borderLeft: "none", // 왼쪽 테두리 없애기
                  borderTop: "0.5px solid #E5F2FC", // 위 테두리 추가
                  borderRight: "0.5px solid #E5F2FC", // 오른쪽 테두리 추가
                  borderBottom: "0.5px solid #E5F2FC", // 아래 테두리 추가
                  color: tempSelectedGender === "여자"
                      ? "#227AF3"
                      : "#4A5B6C",
                  fontFamily: "Pretendard",
                  fontSize: "15px",
                  fontWeight: 500,
                  lineHeight: "22px",
                  letterSpacing: "-0.45px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
            >
              여자
            </p>
          </div>

          <div
              className={"bottom-sheet-button-container"}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
          >
            {/* 선택하기 버튼 */}
            <button
                onClick={() => {
                  setSelectedGender(tempSelectedGender); // 선택 확정
                  onSelect(tempSelectedGender); // 부모 컴포넌트에 선택값 전달
                  onClose(); // 바텀시트 닫기
                }}
                disabled={!tempSelectedGender} // 아무것도 선택하지 않으면 비활성화
                style={{
                  marginTop: "52px",
                  width: "100%",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: tempSelectedGender ? "#227AF3" : "#D1D5DB", // 선택된 값이 없으면 비활성화 스타일
                  border: "none",
                  cursor: tempSelectedGender ? "pointer" : "not-allowed",
                  color: "var(--Grayscale-White, #FFF)",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Pretendard",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                  letterSpacing: "-0.48",
                  marginBottom: "48px",
                }}
            >
              선택하기
            </button>
          </div>
        </div>
      </>
  );
};

export default GenderBottomSheet;
