import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setFirebaseToken} from "../redux/UserStore";
import {useNavigate} from "react-router-dom";

const Main = () => {
  const token = useSelector((state) => state.auth.token);
  const firebaseToken = new URL(window.location.href).searchParams.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (firebaseToken) {
      alert(firebaseToken);
      dispatch(setFirebaseToken(firebaseToken));
    }

    if (!token) {
      alert('로그인이 필요합니다.');
      navigate('/mission-mate/login');
    }

  }, [navigate, dispatch, firebaseToken]);

  return (
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <h1>메인 페이지 {token}</h1>
        <h1>firebaseToken {firebaseToken}</h1>
      </div>
  );
};

export default Main;
