import React, {useEffect, useState} from "react";
import ic_down from "../../images/ic__down.png";
import GenderBottomSheet from "./GenderBottomSheet";
import BirthdayBottomSheet from "./BirthdayBottomSheet";

const BirthdayInput = ({onChange, defaultBirth}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedBirthday, setSelectedBirthday] = useState(defaultBirth || "");

  useEffect(() => {
    if (defaultBirth) {
      setSelectedBirthday(defaultBirth);
    }
  }, [defaultBirth]);

  const handleGenderSelect = (birthday) => {
    setSelectedBirthday(birthday);
    setIsOpen(false);
    onChange?.(birthday);
  };

  return (
      <>
        <div>
          <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: '16px',
                marginRight: '16px',
              }}
          >
            <div
                onClick={() => setIsOpen(true)}
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '48px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: '8px',
                  marginTop: '16px',
                  marginLeft: '16px',
                  marginRight: '16px',
                  backgroundColor: '#EBEFF6',
                }}
            ><p
                style={
                  {
                    color: '#4A5B6C',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '-0.42px',
                    marginLeft: '12px',
                  }
                }
            >
              생년월
            </p>

              <div
                  style={
                    {
                      display: 'flex',
                      alignItems: 'center',
                    }
                  }
              >
                <p
                    style={
                      selectedBirthday
                          ? {
                            color: 'var(--Grayscale-Gray_80, #4A5B6C)',
                            fontFamily: 'Pretendard',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.42px',
                            marginRight: '2px',
                          } :
                          {
                            color: 'var(--Grayscale-Gray_40, #95A5B6)',
                            textAlign: 'center',
                            fontFamily: 'Pretendard',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.42px',
                            marginRight: '2px',
                          }
                    }
                >
                  {selectedBirthday || "선택 입력"}
                </p>
                <img src={ic_down} alt="down arrow"
                     style={
                       {
                         width: '16px',
                         height: '16px',
                         marginRight: '12px',
                       }
                     }
                />
              </div>

            </div>

            <BirthdayBottomSheet
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSelect={handleGenderSelect}
                defaultValue={selectedBirthday}
            />

          </div>
        </div>
      </>
  )
}

export default BirthdayInput;
