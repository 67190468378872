import React, {useEffect, useState} from 'react';
import ic_back from "../../../../images/ic_back.png";
import MissionUpdateTitle from "./MissionUpdateTitle";
import MissionUpdateDate from "./MissionUpdateDate";
import {create, update} from "../../../../api/MissionCreateApi";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {findMissionDetail} from "../../../../api/MissionDetailPageApi";

const MissionUpdatePage = () => {
  // 현재 스텝을 관리하는 상태
  const location = useLocation();
  const missionId = location.state?.missionId;
  const [currentStep, setCurrentStep] = useState('title');
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  // 미션 데이터를 관리하는 상태
  const [missionData, setMissionData] = useState({
    title: '',
    startDate: null,
    endDate: null,
    selectedDays: []
  });

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const response = await findMissionDetail(missionId, token, navigate);
        setMissionData({
          title: response.title,
          startDate: response.startDate,
          endDate: response.endDate,
          selectedDays: response.days
        });
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, [token, navigate, missionId]); // missionId만 필요한 의존성 포함

  const convertKoreanDayToEnum = (koreanDay) => {
    const dayMap = {
      '월': 'MONDAY',
      '화': 'TUESDAY',
      '수': 'WEDNESDAY',
      '목': 'THURSDAY',
      '금': 'FRIDAY',
      '토': 'SATURDAY',
      '일': 'SUNDAY'
    };

    return dayMap[koreanDay];
  };


  const convertSelectedDaysToEnum = (selectedDays) => {
    return selectedDays.map(day => convertKoreanDayToEnum(day));
  };




  // 타이틀 입력 완료 시 호출될 핸들러
  const handleTitleSubmit = (title) => {
    setMissionData(prev => ({
      ...prev,
      title
    }));
    setCurrentStep('date');
  };

  // 날짜 및 요일 선택 완료 시 호출될 핸들러
  const handleDateSubmit = (dateData) => {
    setMissionData(prev => ({
      ...prev,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
      selectedDays: dateData.selectedDays
    }));
    // 여기서 최종 데이터를 처리하거나 API 호출 등을 수행할 수 있습니다
    console.log('Final Mission Data:', {
      ...missionData,
      startDate: dateData.startDate,
      endDate: dateData.endDate,
      selectedDays: dateData.selectedDays
    });

    update(
        missionId,
        missionData.title,
        dateData.startDate,
        dateData.endDate,
        convertSelectedDaysToEnum(dateData.selectedDays),
        token,
        navigate
    ).then(() => {
      navigate('/mission-mate/mission-list');
    });
  };

  // 뒤로가기 처리
  const handleBack = () => {
    if (currentStep === 'title') {
      navigate('/mission-mate/mission-setting', {state: {missionId}});
    }
    if (currentStep === 'date') {
      setCurrentStep('title');
    } else {
      // 필요한 경우 페이지 이탈 로직 추가
    }
  };

  return (
      <div>
        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '21px',
              position: 'relative',
            }}
        >
          <img
              src={ic_back}
              onClick={handleBack}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '16px',
                marginRight: '16px',
                position: 'absolute',
                cursor: 'pointer',
              }}
          />
          <p style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '26px',
            letterSpacing: '-0.54px',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}>
            미션 수정
          </p>
        </div>



        {(currentStep === 'title') ? (
            <MissionUpdateTitle
                onSubmit={handleTitleSubmit}
                initialValue={missionData.title}
            />
        ) : (
            <MissionUpdateDate
                onSubmit={handleDateSubmit}
                initialStartDate={missionData.startDate}
                initialEndDate={missionData.endDate}
                initialSelectedDays={missionData.selectedDays}
            />
        )}
      </div>
  );
};

export default MissionUpdatePage;
