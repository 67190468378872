import React, {useState, useEffect} from 'react';
import homeIndicator from "../../../../images/Home Indicator.png";
import ic_back from "../../../../images/ic_back.png";
import ic_before from "../../../../images/ic_before.png";
import ic_after from "../../../../images/ic_after.png";


const MissionUpdateDateBottomSheet = ({
  activeInput,
  setActiveInput,
  setShowBottomSheet,
  showBottomSheet,
  selectedStartDate,
  selectedEndDate,
  setSelectedStartDate,
  setSelectedEndDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // 달력 데이터 생성
  const generateCalendarDays = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();

    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const days = [];
    const firstDayOfWeek = firstDay.getDay();

    // 첫 주의 빈 날짜 채우기
    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(null);
    }

    // 날짜 채우기
    for (let day = 1; day <= lastDay.getDate(); day++) {
      days.push(new Date(year, month, day));
    }

    return days;
  };

  // 날짜 선택 가능 여부 체크
  const isDateSelectable = (date) => {
    if (!date) {
      return false;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (date < today) {
      return false;
    }

    if (activeInput === 'end' && selectedStartDate) {
      return date > selectedStartDate;
    }

    if (activeInput === 'start' && selectedEndDate) {
      return date < selectedEndDate;
    }

    return true;
  };

  // 날짜 선택 핸들러
  const handleDateSelect = (date) => {
    if (!isDateSelectable(date)) {
      return;
    }

    setSelectedDate(date);

    if (activeInput === 'start') {
      setSelectedStartDate(date);
    } else {
      setSelectedEndDate(date);
    }

    setTimeout(() => {
      setShowBottomSheet(false);
      setActiveInput(null);
    }, 500);
  };

  // 월 이동 핸들러
  const handleMonthChange = (increment) => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(newDate.getMonth() + increment);

    // 현재 달보다 이전으로는 이동 불가
    const today = new Date();
    if (newDate.getFullYear() < today.getFullYear() ||
        (newDate.getFullYear() === today.getFullYear() &&
            newDate.getMonth() < today.getMonth())) {
      return;
    }

    setCurrentMonth(newDate);
  };

  // 날짜 포맷팅
  const formatDate = (date) => {
    if (!date) {
      return '';
    }
    const year = date.getFullYear().toString().slice(2); // 2025 -> 25
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}년${month}월${day}일`;
  };

  const isSelectedDate = (date) => {
    if (!date || !selectedDate) {
      return false;
    }
    return date.getTime() === selectedDate.getTime();
  };

  // 현재 달인지 체크
  const isCurrentMonth = () => {
    const today = new Date();
    return currentMonth.getFullYear() === today.getFullYear() &&
        currentMonth.getMonth() === today.getMonth();
  };

  return (

      <div
          className="bottom-sheet-container"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            visibility: showBottomSheet ? 'visible' : 'hidden',
          }}
      >
        {/* Backdrop */}
        <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(22, 22, 22, 0.80)',
              opacity: showBottomSheet ? 1 : 0,
              transition: 'opacity 0.3s ease',
            }}
            onClick={() => setShowBottomSheet(false)}
        />



        {/* Bottom Sheet Content */}
        <div
            style={{
              position: 'relative',
              width: '100%',
              backgroundColor: '#FFF',
              borderRadius: '8px 8px 0 0',
              padding: '24px',
              paddingTop: '0',
              transform: `translateY(${showBottomSheet ? '0' : '100%'})`,
              transition: 'transform 0.3s ease',
            }}
        >
          {/* Calendar Header */}
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: '8px',
              }}
          >
            <img
                src={homeIndicator}
                style={{
                  width: "64px",
                  height: "5px",
                }}
                alt="indicator"
            />
          </div>

          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '21px',
                position: 'relative',
                marginLeft: '0px',
                paddingLeft: '0px',
              }}
          >
            <img
                src={ic_back}
                style={{
                  width: '24px',
                  height: '24px',
                  marginLeft: '0px',
                  position: 'absolute',
                }}
            />
            <p style={{
              color: '#29333E',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '26px',
              letterSpacing: '-0.54px',
              width: '100%',
              textAlign: 'center',
              margin: 0,
            }}>
              날짜 선택
            </p>
          </div>


          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '24px',
            marginTop: '27px',
          }}>

            {isCurrentMonth() ? <img
                src={ic_before}
                style={{
                  width: '16px',
                  height: '16px',
                }}
                />
                : <img
                    src={ic_back}
                    style={{
                      width: '16px',
                      height: '16px',
                    }}
                    onClick={() => handleMonthChange(-1)}
                />}
            <div style={{
              color: '#3B4856',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '-0.48px',
              marginLeft: '8px',
              marginRight: '8px',

            }}>
              {currentMonth.getFullYear()}년 {currentMonth.getMonth() + 1}월
            </div>

            <img
                onClick={() => handleMonthChange(1)}
                src={ic_after}
                style={{
                  width: '16px',
                  height: '16px',
                }}
            />
          </div>

          {/* Calendar Grid */}
          <div style={{marginBottom: '16px'}}>
            {/* Weekday Headers */}
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
              textAlign: 'center',
              marginBottom: '8px'
            }}>
              {['일', '월', '화', '수', '목', '금', '토'].map((day, index) => (
                  <div
                      key={day}
                      style={{
                        padding: '8px',
                        ...(index === 0 || index === 6 ? {
                          color: '#227AF3',
                          fontFamily: 'Pretendard',
                          fontSize: '13px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '18px',
                          letterSpacing: '-0.39px'
                        } : {
                          color: '#3B4856',
                          fontFamily: 'Pretendard',
                          fontSize: '13px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '18px',
                          letterSpacing: '-0.39px'
                        })
                      }}
                  >
                    {day}
                  </div>
              ))}
            </div>

            {/* Calendar Days */}
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
              gap: '8px'
            }}>
              {generateCalendarDays().map((date, index) => (
                  <div
                      key={index}
                      onClick={() => date && handleDateSelect(date)}
                      style={{
                        padding: '8px',
                        textAlign: 'center',
                        cursor: isDateSelectable(date) ? 'pointer'
                            : 'default',
                        color: !date ? 'transparent' :
                            !isDateSelectable(date) ? '#CFD9E2' :
                                isSelectedDate(date) ? '#227AF3' : '#000',
                        backgroundColor: isSelectedDate(date)
                            ? 'rgba(34, 122, 243, 0.20)' : 'transparent',
                        borderRadius: '4px',
                        border: isSelectedDate(date) ? '1.5px solid #227AF3'
                            : 'none',
                        fontSize: '20px',
                        fontFamily: 'Pretendard',
                        fontWeight: '500',
                        lineHeight: '28px',
                        letterSpacing: '-0.6px'
                      }}
                  >
                    {date ? date.getDate() : ''}
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default MissionUpdateDateBottomSheet;
