import React, {useEffect, useState} from 'react';
import ic_setting from "../../../images/ic_setting.png";
import profile_unselected from "../../../images/profile_unselected (1).png";
import empty_mission_img from "../../../images/empty_mission_img.png";
import plus_btn from "../../../images/plus_btn.png";
import MissionListItem from "./MissionListItem";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {findAll} from "../../../api/MissionListPageApi";
import MissionRecordUpdateBottomSheet
  from "../detail/MissionRecordUpdateBottomSheet";
import InviteBottomSheet from "../../invite/InviteBottomSheet";
import {saveFcmToken} from "../../../api/FcmRegisterApi";
import {setFirebaseToken} from "../../../redux/UserStore";
import {getMemberInfo} from "../../../api/ProfileSetupPageApi";
import EmptyMissionList from "./EmptyMissionList";

const MissionListPage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [inviteToken, setInviteToken] = useState(
      new URL(window.location.href).searchParams.get("inviteToken"));
  const fcmToken = useSelector((state) => state.firebase.firebaseToken);

  const handleCreateMission = () => {
    navigate('/mission-mate/mission-create');
  }

  const [nickname, setNickname] = useState(""); // 닉네임 상태 관리
  const [missions, setMissions] = useState([]);

  useEffect(() => {
    if (fcmToken) {
      saveFcmToken(fcmToken, token, navigate).then(
          r => console.log('fcmToken 저장 완료'));
    }

    const loadData = async () => {
      try {
        const response = await findAll(token, navigate);
        await getMemberInfo(token).then(r => setNickname(r.nickname));
        setMissions(response.missionListResponses);
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, [token, navigate, fcmToken]);

  const handleCloseBottomSheet = () => {
    setInviteToken(null);
    // URL에서 inviteToken 파라미터 제거
    const url = new URL(window.location.href);
    url.searchParams.delete("inviteToken");
    window.history.replaceState({}, '', url);
  };

  return (
      <div
          style={{
            minHeight: '100vh',
            backgroundColor: '#EBEFF6',
            display: 'flex',        // 추가
            flexDirection: 'column' // 추가
          }}
      >
        <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
        >
          <img
              onClick={() => navigate("/mission-mate/profile-setting")}
              src={ic_setting}
              style={{
                width: '24px',
                height: '24px',
                marginTop: '12px',
                marginRight: '16px',
              }}
          />
        </div>

        <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '24px',
            }}
        >
          <p
              style={{
                color: '#3B4856',
                fontFamily: 'Pretendard',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '32px',
                letterSpacing: '-0.72px',
                marginLeft: '16px',
              }}
          >
            {nickname}의 <br/>
            미션 리스트
          </p>
          <img
              src={profile_unselected}
              style={{
                width: '80px',
                height: '80px',
                marginTop: '16px',
                marginRight: '16px',
              }}
          />
        </div>


        {missions.length > 0
            ? missions.map((mission) => (
                <MissionListItem key={mission.missionId} {...mission} />
            ))
            : <EmptyMissionList /> // 또는 표시하고 싶은 다른 컴포넌트
        }


        <div
            style={
              {
                display: 'flex',
                justifyContent: 'flex-end',  // center에서 변경
                marginTop: 'auto'
              }
            }
        >
          <img
              src={plus_btn}
              onClick={handleCreateMission}
              style={{
                width: '64px',
                height: '64px',
                marginLeft: 'auto',
                marginRight: '16px',
                marginBottom: '40px',
                marginTop: 'auto',
              }}
          />
        </div>

        {inviteToken !== null && (
            <InviteBottomSheet
                onClose={handleCloseBottomSheet}
                inviteToken={inviteToken}
            />
        )}
      </div>
  )
}

export default MissionListPage;

