import React from 'react';
import AppleLoginButton from './AppleLoginButton';
import KakaoLoginButton from './KakaoLoginButton';
import GoogleLoginButton from "./GoogleLoginButton";
import highfive from "../../images/illust.png";
import logo from "../../images/logo.png";
import GuestLoginButton from "./GuestLoginButton";

const LoginPage = () => {
  return (
      <div
          style={
            {
              height: '100vh',
              backgroundColor: '#EBEFF6'}
          }
      >

        <div style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <img src={logo}
                 style={{marginTop: '75px', width: '160px', height: '74px'}}/>
            <img src={highfive}
                 style={{marginTop: '30px', width: '250px', height: '224px'}}/>
          </div>

          <div style={{
            marginBottom: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            {/*<GuestLoginButton/>*/}
            <AppleLoginButton/>
            <KakaoLoginButton/>
            <GoogleLoginButton/>
          </div>
        </div>
      </div>
  );
};

export default LoginPage;
