import { Routes, Route } from 'react-router-dom';
import './App.css';
import LoginPage from "./page/login/LoginPage";
import KakaoLoginPage from "./page/login/KakaoLoginPage";
import Main from "./page/Main";
import AppleLoginButton from "./page/login/AppleLoginButton";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/main" element={<Main />} />
          <Route path="/mission-mate/login" element={<LoginPage />} />
          <Route path="/mission-mate/auth/login" element={<KakaoLoginPage />} />
          <Route path="/mission-mate/auth/login/apple" element={<AppleLoginButton />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
