import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// 1. 각각의 초기 상태 설정
const initialAuthState = {
  token: ""
};

const initialFirebaseState = {
  firebaseToken: ""
};

// 2. 액션 타입 정의
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN';

// 3. 액션 생성자 함수들
export function LoginToken(token) {
  return {
    type: LOGIN,
    token: token
  };
}

export function LogoutUser() {
  return {
    type: LOGOUT
  };
}

export function setFirebaseToken(token) {
  return {
    type: SET_FIREBASE_TOKEN,
    token: token
  };
}

// 4. 각각의 리듀서 정의
function authReducer(state = initialAuthState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, token: action.token };
    case LOGOUT:
      return { ...state, token: "" };
    default:
      return state;
  }
}

function firebaseReducer(state = initialFirebaseState, action) {
  switch (action.type) {
    case SET_FIREBASE_TOKEN:
      return { ...state, firebaseToken: action.token };
    default:
      return state;
  }
}

// 5. 각각의 Persist 설정
const authPersistConfig = {
  key: "auth",
  storage: storage
};

const firebasePersistConfig = {
  key: "firebase",
  storage: storage
};

// 6. 각각의 Persist reducer 생성
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedFirebaseReducer = persistReducer(firebasePersistConfig, firebaseReducer);

// 7. Root reducer 생성 - 두 리듀서 결합
const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  firebase: persistedFirebaseReducer
});

export default rootReducer;
