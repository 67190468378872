import React, {useState} from 'react';

import MissionGoalProgressDetailItem from "./MissionGoalProgressDetailItem";
import MissionDetailUserProfile from "./MissionDetailUserProfile";

const MissionGoalProgressDetail = ({friends, totalPossibleDays}) => {
  return (

      <div style={{
        borderRadius: '8px',
        backgroundColor: '#FFF',
        marginLeft: '16px',
        marginRight: '16px',
      }}>

        <p
            style={{
              color: '#4A5B6C',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '-0.42px',
              marginLeft: '16px',
              paddingTop: '16px',
              marginBottom: '0px',
            }}
        >
          달성 현황
        </p>

        <div
            style={{
              paddingBottom: '16px',
            }}
        >

          {friends.map((friend) => (
              <MissionGoalProgressDetailItem
                  key={friend.id}
                  nickName={friend.nickName}
                  imageUrl={friend.imageUrl}
                  totalPossibleDays={totalPossibleDays}
                  finishGoalCount={friend.finishDays}
              />
          ))}

        </div>

      </div>
  )
}

export default MissionGoalProgressDetail;
