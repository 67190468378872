import React, {useEffect, useState} from 'react';
import profile from "../../images/profile_unselected (1).png";
import {useNavigate} from "react-router-dom";
import {getMemberInfo} from "../../api/ProfileSetupPageApi";
import {useSelector} from "react-redux";

const ProfileSettingUserInfo = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const [nickname, setNickname] = useState(""); // 닉네임 상태 관리
  const [birth, setBirth] = useState(""); // 생년월일 상태 관리
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [socialType, setSocialType] = useState("");


  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getMemberInfo(token);
        setNickname(response.nickname);
        setBirth(response.birth);
        setProfileImageUrl(response.profileImageUrl);
        setSocialType(response.socialType);
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, []);

  return (
      <div style={{
        borderRadius: '8px',
        background: '#EBEFF6',
        marginLeft: '16px',
        marginRight: '16px',
        paddingTop: '24px',
        paddingBottom: '24px',
        position: 'relative'  // 편집 버튼의 기준점
      }}>
        <div style={{
          position: 'absolute',
          right: '16px',
          top: '16px',
          borderRadius: '50px',
          background: '#5A6F83',
          border: 'none',
          color: 'white',
          fontSize: '12px',
        }}>
          <p
              onClick={() => navigate('/mission-mate/profile-setup',
                  {state: {isLandingProfileSetting: true}})}
              style={{
                color: '#FFFFFF',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '-0.36px',
                marginTop: '1px',
                marginBottom: '1px',
                padding: '4px 12px',
                marginLeft: '3px',
                marginRight: '3px',
              }}
          >
            편집
          </p>
        </div>

        <div style={{display: 'flex'}}>
          <img
              src={profile}
              style={{
                width: '60px',
                height: '60px',
                marginLeft: '16px',
              }}
          />

          <div style={{marginLeft: '16px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{
              color: '#7E90A4',
              fontFamily: 'Pretendard',
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '14px',
              letterSpacing: '-0.3px',
              width: '48px'
            }}>
              이름
            </span>
              <span style={{
                marginLeft: '16px',
                color: '#4A5B6C',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '-0.36px',
              }}>
              {nickname}
            </span>
            </div>

            <div style={{
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center'
            }}>
            <span style={{
              color: '#7E90A4',
              fontFamily: 'Pretendard',
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '14px',
              letterSpacing: '-0.3px',
              width: '48px'
            }}>
              가입경로
            </span>
              <span style={{
                marginLeft: '16px',
                color: '#4A5B6C',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '-0.36px',
              }}>
              {socialType}
            </span>
            </div>

            <div style={{
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center'
            }}>
            <span style={{
              color: '#7E90A4',
              fontFamily: 'Pretendard',
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '14px',
              letterSpacing: '-0.3px',
              width: '48px'
            }}>
              가입일
            </span>
              <span style={{
                marginLeft: '16px',
                color: '#4A5B6C',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '-0.36px',
              }}>
              {birth}
            </span>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ProfileSettingUserInfo;
