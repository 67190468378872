import homeIndicator from "../../images/Home Indicator.png";
import React, {useEffect, useState} from "react";
import highfive from "../../images/illust.png";
import {
  getParticipateInMissionByToken,
  participateInMissionByToken
} from "../../api/InviteBottomSheetApi";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {findMissionDetail} from "../../api/MissionDetailPageApi";
import ToastAlert from "../common/ToastAlert";

const InviteBottomSheet = ({onClose, inviteToken}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [nickName, setNickName] = useState("");
  const [missionTitle, setMissionTitle] = useState("");
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [toastVisible, setToastVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleParticipate = () => {
    participateInMissionByToken(inviteToken, token, navigate).then((response) => {
      if (response.response?.status === 400) {
        // 에러 상황
        setErrorMessage(response.response?.data.message)
        setToastVisible(true);

        // 토스트 메시지가 보이고 난 후(3초) 바텀시트를 닫고 페이지 이동
        setTimeout(() => {
          setIsClosing(true);
          setTimeout(() => {
            onClose();
            navigate('/mission-mate/mission-list');
          }, 300); // 바텀시트 애니메이션 시간
        }, 3000); // 토스트 표시 시간

      } else {
        // 성공 상황
        setIsClosing(true);
        setTimeout(() => {
          onClose();
          navigate('/mission-mate/mission-list');
        }, 300);
      }
    });
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getParticipateInMissionByToken(inviteToken, token, navigate);
        setNickName(response.userName)
        setMissionTitle(response.missionTitle)
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, [token, navigate]);


  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
      <>
        {/* 오버레이 */}
        <div
            onClick={handleClose}
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              transition: 'opacity 300ms ease-out',
              opacity: isClosing ? 0 : 1,
              zIndex: 999
            }}
        />

        {/* 바텀 시트 */}
        <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: '#FFFFFF',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              transition: 'transform 300ms ease-out',
              transform: isClosing ? 'translateY(100%)' : 'translateY(0)',
              zIndex: 1000,
              textAlign: 'center'  // 전체 컨테이너에 중앙 정렬 적용
            }}
        >
          {/* Home Indicator */}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 0',
            marginBottom: '67px',
          }}>
            <img
                src={homeIndicator}
                style={{
                  width: "64px",
                  height: "5px",
                }}
                alt="indicator"
            />
          </div>

          {/* 환영 메시지 */}
          <div>
            <span style={{
              color: '#227AF3',
              fontFamily: 'Pretendard',
              fontSize: '22px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '30px',
              letterSpacing: '-0.66px',
            }}>
              {nickName}
            </span>
            <span style={{
              color: '#5A6F83',
              fontFamily: 'Pretendard',
              fontSize: '22px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '30px',
              letterSpacing: '-0.66px',
            }}>
              님의<br/>초대 링크를 받고 오셨네요!
            </span>
          </div>

          <img
              src={highfive}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '250px',
                height: '224px',
                marginTop: '40px',
                marginBottom: '40px',
              }}
              alt="highfive"
          />

          <span
              style={{
                color: '#3B4856',
                fontFamily: 'Pretendard',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '30px',
                letterSpacing: '-0.66px',
              }}
          >
              {missionTitle}
          </span>
          <span
              style={{
                color: '#5A6F83',
                fontFamily: 'Pretendard',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '30px',
                letterSpacing: '-0.66px',
              }}
          >
              <br/>미션에 함께하시겠습니까?
          </span>


          <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
          >
            <button
                onClick={handleParticipate}
                style={{
                  width: '100%',
                  height: '52px',
                  backgroundColor: '#227AF3',
                  color: 'white',
                  border: 'none',
                  marginTop: '120px',
                  marginLeft: '16px',
                  marginRight: '16px',
                  borderRadius: '8px',
                  marginBottom: '48px',
                }}
            >
              시작하기
            </button>
          </div>

          <ToastAlert
              message={errorMessage}
              isVisible={toastVisible}
              setIsVisible={setToastVisible}
          />
        </div>
      </>
  );
}

export default InviteBottomSheet;
