import React, {useEffect, useState} from "react";
import ic_down from "../../images/ic__down.png";
import GenderBottomSheet from "./GenderBottomSheet";

const GenderInput = ({onChange, defaultGender}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState(defaultGender || ""); // 초기값 설정

  useEffect(() => {
    if (defaultGender) {
      setSelectedGender(defaultGender);
    }
  }, [defaultGender]); // selectedGender 제거

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setIsOpen(false);
    onChange?.(gender);
  };

  return (
      <>
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: '16px',
              marginRight: '16px',
            }}
        >
        <div
            onClick={() => setIsOpen(true)}
            style={{
              display: 'flex',
              width: '100%',
              height: '48px',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: '8px',
              marginTop: '16px',
              marginLeft: '16px',
              marginRight: '16px',
              backgroundColor: '#EBEFF6',
            }}
        >
          <p
              style={
                {
                  color: 'var(--Grayscale-Gray_80, #4A5B6C)',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.42px',
                  marginLeft: '12px',
                }
              }
          >
            성별
          </p>

          <div
              style={
                {
                  display: 'flex',
                  alignItems: 'center',
                }
              }
          >
            <p
                style={
                  selectedGender
                      ? {
                        color: 'var(--Grayscale-Gray_80, #4A5B6C)',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                        letterSpacing: '-0.42px',
                        marginRight: '2px',
                      } :
                      {
                        color: 'var(--Grayscale-Gray_40, #95A5B6)',
                        textAlign: 'center',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                        letterSpacing: '-0.42px',
                        marginRight: '2px',
                      }
                }
            >
              {selectedGender || "선택 입력"}
            </p>
            <img src={ic_down} alt="down arrow"
                 style={
                   {
                     width: '16px',
                     height: '16px',
                     marginRight: '12px',
                   }
                 }
            />
          </div>

        </div>

        <GenderBottomSheet
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onSelect={handleGenderSelect}
            defaultValue={selectedGender}
        />
        </div>
      </>
  );
};

export default GenderInput;
