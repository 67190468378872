import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {postKakaoLogin} from "../../api/KakaoLoginApi";
import { LoginToken } from "../../redux/UserStore";
import { useDispatch, useSelector } from "react-redux";

const KakaoLoginPage = () => {
  const code = new URL(window.location.href).searchParams.get("code");
  const user = useSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogin = async () => {
      if (code) {
        try {
          const response = await postKakaoLogin(code);
          dispatch(LoginToken(response.accessToken));
          navigate("/main");

        } catch (error) {
          alert(error);
        }
      }
    };

    handleLogin();
  }, [code, dispatch, navigate]);

  return <div />;
}

export default KakaoLoginPage;
