import axios from 'axios';
import {url} from './ApiConstant';

export async function findMissionDetail(missionId, token, navigate) {
  try {
    const response = await axios.get(
        `${url}/missions/${missionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', { replace: true }); // 로그인 페이지로 리디렉션
    }
  }
}

export async function findMissionDetailDate(missionId, memberId, yearMonth, token, navigate) {
  try {
    const response = await axios.get(
        `${url}/missions/${missionId}/members/${memberId}`,
        {
          params: {
            yearMonth: yearMonth // "2025-03" 형식으로 전달
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', { replace: true });
    }
  }
}
