import React, {useEffect, useState} from 'react';
import ic_back from "../../../images/ic_back.png";
import ic_setting from "../../../images/ic_setting.png";
import ic_down_btn from "../../../images/ic_down_btn.png";
import ic_up_btn from "../../../images/ic_up_btn.png";
import MissionListItem from "../list/MissionListItem";
import MissionDetailUserProfile from "./MissionDetailUserProfile";
import MissionDetailDatePage from "./MissionDetailDatePage";
import MissionGoalProgressDetail from "./MissionGoalProgressDetail";
import {findMissionDetail} from "../../../api/MissionDetailPageApi";
import {findAll} from "../../../api/MissionListPageApi";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const MissionDetailPage = () => {

  const location = useLocation();
  const missionId = location.state?.missionId;
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const [title, setTitle] = useState("1일 1시간 디자인 실천하기"); // 닉네임 상태 관리
  const [startTime, setStartTime] = useState("2025년 1월 25일 -"); // 닉네임 상태 관리
  const [endTime, setEndTime] = useState("2025년 1월 25일 -"); // 닉네임 상태 관리
  const [finished, setFinished] = useState(false); // 닉네임 상태 관리
  const [totalPossibleDays, setTotalPossibleDays] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedUserDetail, setSelectedUserDetail] = useState(false);

  const handleSelectedUSerDetail = () => {
    setSelectedUserDetail(!selectedUserDetail);
  }

  const [friends, setFriends] = useState([])

  const handleSelect = (id) => {
    setSelectedId(id);
  };

  const handleOnClickSetting = () => {
    navigate("/mission-mate/mission-setting", {state: {missionId}});
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await findMissionDetail(missionId, token, navigate);
        setTitle(response.title);
        setStartTime(formatDate(response.startDate));
        setEndTime(formatDate(response.endDate));
        setFriends(response.missionInProgressResponses)
        setTotalPossibleDays(response.totalPossibleDays);
        setFinished(response.finished);
        if (response.missionInProgressResponses.length > 0) {
          setSelectedId(response.missionInProgressResponses[0].memberId);
        }
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, [token, navigate]);

  return (
      <div
          style={{
            minHeight: '100vh',  // height를 minHeight로 변경
            backgroundColor: '#EBEFF6',
            display: 'flex',        // 추가
            flexDirection: 'column'
          }}
      >
        <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
        >
          <img
              onClick={() => navigate("/mission-mate/mission-list")}
              src={ic_back}
              style={{
                width: '24px',
                height: '24px',
                marginTop: '22px',
                marginLeft: '16px',
              }}
          />

          <img
              src={ic_setting}
              onClick={handleOnClickSetting}
              style={{
                width: '24px',
                height: '24px',
                marginTop: '22px',
                marginRight: '16px',
              }}
          />
        </div>

        <div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '36px'
          }}
               onClick={handleSelectedUSerDetail}
          >
            <p
                style={{
                  color: '#3B4856',
                  fontFamily: 'Pretendard',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '28px',
                  letterSpacing: '-0.6px',
                  marginLeft: '16px',
                  marginTop: '0px',     // 이 부분 추가
                  marginBottom: '0px',
                }}
            >
              {title}
            </p>

            <img
                src={selectedUserDetail ? ic_up_btn : ic_down_btn}
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: '4px',
                }}
            />
          </div>

          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '4px',
              }}
          >
            <span
                style={{
                  overflow: 'hidden',
                  color: '#7E90A4',
                  textOverflow: 'ellipsis',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.42px',
                  marginLeft: '16px',
                }}
            >
              {startTime} - {endTime}
            </span>

            {finished ?
                  <span
                      style={{
                        borderRadius: '50px',
                        textAlign: 'center',
                        fontFamily: 'Pretendard',
                        fontSize: '8px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '12px',
                        letterSpacing: '-0.24px',
                        backgroundColor: '#227AF3',
                        color: '#FFF',
                        marginLeft: '14px',
                        marginRight: '14px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                      }}
                  >
                    종료
                  </span>
                : <></>}
          </div>

          {/* 달성 현황*/}
          {selectedUserDetail ? <MissionGoalProgressDetail
              friends={friends}
              totalPossibleDays={totalPossibleDays}
          /> : <></>}


          {/* 미션 달력*/}
          <div style={{
            display: 'flex',
            justifyContent: 'left',
          }}>
            {friends.map((friend) => (
                <MissionDetailUserProfile
                    key={friend.id}
                    {...friend}
                    isSelected={friend.memberId === selectedId}
                    onSelect={() => handleSelect(friend.memberId)}
                />
            ))}
          </div>
        </div>

        <div
            style={{
              marginBottom: '20px',
            }}
        >

          {selectedId && (
              <MissionDetailDatePage
                  missionId={missionId}
                  memberId={selectedId}
              />)}
        </div>
      </div>
  )
}

export default MissionDetailPage;
