import React, {useState} from 'react';
import profile from "../../../images/profile_2.png";

const MissionDetailUserProfile = ({memberId, nickName, imageUrl, isSelected, onSelect}) => {

  return (
      <div
          onClick={onSelect}
      >
        <div
            style={{
              marginTop: '28px',
              position: 'relative',  // 추가
              display: 'flex',
              width: '60px',
              padding: '12px 8px 4px 8px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
        >
          <div
              style={{
                position: 'absolute',  // 추가
                top: '0',             // 추가
                zIndex: 1,            // 추가
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50px',
                background: isSelected ? '#227AF3' : '#FFF',
                marginTop: '3px',
                marginBottom: '0px',
              }}
          >
          <p
                style={{
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '8px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '12px',
                  letterSpacing: '-0.24px',
                  color: isSelected ? '#FFF' : '#227AF3',
                  marginLeft: '14px',
                  marginRight: '14px',
                  marginTop: '5px',
                  marginBottom: '5px',
                }}
            >
              {nickName}
            </p>
          </div>

          <div
              onClick={onSelect}
              style={{
                borderRadius: '100px',
                border: isSelected ? '1px solid #227AF3' : '',
                background: '#C0DFF8',
                padding: '12px 8px 4px 8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
          >
            <img
                src={profile}
                style={{
                }}
            />
          </div>
        </div>
      </div>
  )
}

export default MissionDetailUserProfile;
