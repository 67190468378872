import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import ic_back from "../../../images/ic_back.png";
import MissionInvite from "./MissionInvite";
import ic_right from "../../../images/ic_right.png";
import PopupPage from "../../common/PopupPage";

const MissionSettingPage = ({}) => {
  const location = useLocation();
  const missionId = location.state?.missionId;
  const navigate = useNavigate();
  const [missionFinish, setMissionFinish] = useState(false);
  const [missionDelete, setMissionDelete] = useState(false);

  const handleBack = () => {
    navigate("/mission-mate/mission-detail", {state: {missionId}});
  }

  const handleMissionUpdate = () => {
    navigate("/mission-mate/mission-update", {state: {missionId}});
  }

  return (
    <div>
      <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '21px',
            position: 'relative',
            marginBottom: '11px',
          }}
      >
        <img
            src={ic_back}
            onClick={handleBack}
            style={{
              width: '24px',
              height: '24px',
              marginLeft: '16px',
              marginRight: '16px',
              position: 'absolute',
              cursor: 'pointer',
            }}
        />
        <p style={{
          color: '#29333E',
          fontFamily: 'Pretendard',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '26px',
          letterSpacing: '-0.54px',
          width: '100%',
          textAlign: 'center',
          margin: 0,
        }}>
          미션 설정
        </p>
      </div>

      <MissionInvite
          missionId={missionId}
      />

      {/* 프렌즈 관리 */}
      <div
          onClick={() => {navigate("/mission-mate/friends-setting", {state: {missionId}})}}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
          }}
      >
        <p
            style={{
              color: '#29333E',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.48px',
              marginLeft: '16px',
              marginTop: '0px',
            }}
        >
          프렌즈 관리
        </p>
        <img
            src={ic_right}
            style={{
              width: '24px',
              height: '24px',
              marginRight: '16px',
            }}
        />
      </div>

      {/* 미션명 수정하기 */}
      <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
          }}
          onClick={handleMissionUpdate}
      >
        <p
            style={{
              color: '#29333E',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.48px',
              marginLeft: '16px',
              marginTop: '0px',
            }}
        >
          미션명 수정하기
        </p>
        <img
            src={ic_right}
            style={{
              width: '24px',
              height: '24px',
              marginRight: '16px',
            }}
        />
      </div>

      {/* 미션 종료하기 */}
      <div
          onClick={() => setMissionFinish(true)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
          }}
      >
        <p
            style={{
              color: '#29333E',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.48px',
              marginLeft: '16px',
              marginTop: '0px',
            }}
        >
          미션 종료하기
        </p>
        <img
            src={ic_right}
            style={{
              width: '24px',
              height: '24px',
              marginRight: '16px',
            }}
        />
      </div>

      {/* 미션 삭제하기 */}
      <div
          onClick={() => setMissionDelete(true)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
          }}
      >
        <p
            style={{
              color: '#29333E',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.48px',
              marginLeft: '16px',
              marginTop: '0px',
            }}
        >
          미션 삭제하기
        </p>
        <img
            src={ic_right}
            style={{
              width: '24px',
              height: '24px',
              marginRight: '16px',
            }}
        />
      </div>

      <div>
        <PopupPage
            isOpen={missionFinish}
            onClose={() => setMissionFinish(false)}
            title="미션을 종료하시겠습니까?"
            description={<>
              프렌즈의 미션 달성도 함께<br/>
              종료되며 다시 시작할 수 없습니다.
            </>}
            onLeftClick={() => console.log('취소')}
            // onRightClick={handleLogout}
            rightButtonText={'종료'}
        />
      </div>

      <div>
        <PopupPage
            isOpen={missionDelete}
            onClose={() => setMissionDelete(false)}
            title="미션을 삭제하시겠습니까?"
            description={<>
              프렌즈의 미션 달성도 함께<br/>
              종료됩니다.
            </>}
            onLeftClick={() => console.log('취소')}
            // onRightClick={handleLogout}
            rightButtonText={'삭제'}
        />
      </div>

    </div>
  );
}

export default MissionSettingPage;
