import React, {useState} from 'react';
import profile_image from "../../../images/profile_unselected (1).png";

const MissionGoalProgressDetailItem = ({id, nickName, imageUrl, totalPossibleDays, finishGoalCount}) => {
  const progressPercentage = finishGoalCount > 0
      ? (finishGoalCount / totalPossibleDays) * 100
      : 0;

  return (
      <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '8px',
          }}
      >
        <img
            src={imageUrl}
            style={{
              width: '32px',
              height: '32px',
              marginLeft: '16px',
            }}
        />

        <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', // 수직 중앙 정렬
              borderRadius: '50px',
              background: '#4A5B6C',
              height: '14px',  // p 태그의 line-height와 동일하게 변경
              marginLeft: '6px',
            }}
        >
          <p
              style={{
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '8px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '13px',  // 부모의 height와 동일하게 변경
                letterSpacing: '-0.24px',
                margin: 0, // 모든 margin 제거
                width: '33.5px',
              }}
          >
            {nickName}
          </p>
        </div>

        {/* 게이지 바 컨테이너 */}
        <div
            style={{
              flex: 1,
              height: '12px',
              backgroundColor: '#EBEFF6',
              borderRadius: '50px',
              marginLeft: '20px',
              marginRight: '12px',
              overflow: 'hidden',  // 진행 바가 컨테이너를 넘어가지 않도록
            }}
        >
          {/* 진행 바 */}
          <div
              style={{
                width: `${progressPercentage}%`,
                height: '100%',
                backgroundColor: '#227AF3',
                borderRadius: '50px',
              }}
          />
        </div>

        {/* 카운트 텍스트 */}
        <p
            style={{
              color: '#227AF3',
              textAlign: 'right',
              fontFamily: 'Pretendard',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '16px',
              letterSpacing: '-0.36px',
              marginRight: '16px',
            }}
        >
          {finishGoalCount}/{totalPossibleDays}
        </p>
      </div>
  )
}

export default MissionGoalProgressDetailItem;
