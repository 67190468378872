import React from 'react';
import { useNavigate } from 'react-router-dom';
import ic_back from "../../../images/ic_back.png";

const TermsPage = () => {
  const navigate = useNavigate();

  return (
      <div style={{

      }}>

        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '21px',
              position: 'relative',
            }}
        >
          <img
              onClick={() => navigate("/mission-mate/setting-info")}
              src={ic_back}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '16px',
                position: 'absolute',
              }}
          />
          <p style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '26px',
            letterSpacing: '-0.54px',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}>
            이용약관
          </p>
        </div>


        {/* Content */}
        <div style={{
          flex: 1,
          overflowY: 'auto',
          padding: '16px',
          fontFamily: 'Pretendard'
        }}>
          {/* 제1조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 1 조 (목적)</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              이 이용 약관(이하 "약관")은 미션 메이트(이하 "서비스")의 이용과 관련하여 서비스 제공자(이하 "회사")와 이용자 간의 권리, 의무 및 책임 사항을 규정하는 것을 목적으로 합니다.
            </p>
          </section>

          {/* 제2조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 2 조 (정의)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                "서비스"란 친구를 초대하여 함께 미션을 수행하는 기능을 제공하는 모바일 애플리케이션을 의미합니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                "이용자"란 본 약관에 따라 서비스를 이용하는 개인을 의미합니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                "계정"이란 이용자가 서비스를 이용하기 위해 등록하는 ID 및 관련 정보를 의미합니다.
              </li>
              <li style={{
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                "미션"이란 이용자가 수행할 수 있는 과제 또는 목표를 의미합니다.
              </li>
            </ol>
          </section>

          {/* 제3조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 3 조 (약관의 효력 및 변경)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                본 약관은 이용자가 서비스에 가입함과 동시에 효력이 발생합니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                회사는 필요 시 본 약관을 변경할 수 있으며, 변경된 약관은 서비스 내 공지사항을 통해 사전 공지합니다.
              </li>
              <li style={{
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                변경된 약관에 대한 동의 여부는 서비스 이용 시 확인할 수 있으며, 변경된 약관에 동의하지 않는 경우 서비스 이용이 제한될 수 있습니다.
              </li>
            </ol>
          </section>



          {/* 제4조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 4 조 (이용계약의 성립)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                이용계약은 이용자가 본 약관에 동의하고, 서비스 이용 신청을 함으로써 성립합니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                회사는 이용 신청을 승인함으로써 이용계약을 체결하며, 특별한 사유가 있는 경우 이용 신청을 거절할 수 있습니다.
              </li>
            </ol>
          </section>

          {/* 제5조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 5 조 (개인정보 보호)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                회사는 이용자의 개인정보를 보호하기 위해 관련 법령을 준수하며, 개인정보 처리방침을 따릅니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                이용자는 개인정보 변경 시 즉시 수정해야 하며, 부정확한 정보 제공으로 인한 불이익은 이용자의 책임입니다.
              </li>
            </ol>
          </section>

          {/* 제6조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 6 조 (서비스 이용)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                이용자는 서비스를 개인적인 목적으로만 사용할 수 있으며, 상업적 이용은 금지됩니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                이용자는 타인의 계정을 도용하거나, 서비스 운영을 방해하는 행위를 해서는 안 됩니다.
              </li>
            </ol>
          </section>

          {/* 제7조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 7 조 (서비스 이용의 제한 및 정지)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                회사는 다음의 경우 이용자의 서비스 이용을 제한하거나 정지할 수 있습니다.
                <ul style={{ marginTop: '8px', marginLeft: '-20px' }}>
                  <li style={{
                    listStyleType: 'disc',
                    marginBottom: '4px',
                    color: '#4B5563',
                    lineHeight: '1.6',
                    fontSize: '14px'
                  }}>
                    서비스 운영을 방해하는 행위를 하는 경우
                  </li>
                  <li style={{
                    listStyleType: 'disc',
                    marginBottom: '4px',
                    color: '#4B5563',
                    lineHeight: '1.6',
                    fontSize: '14px'
                  }}>
                    타인의 권리를 침해하는 경우
                  </li>
                  <li style={{
                    listStyleType: 'disc',
                    color: '#4B5563',
                    lineHeight: '1.6',
                    fontSize: '14px'
                  }}>
                    기타 관련 법령을 위반하는 경우
                  </li>
                </ul>
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                이용 제한 및 정지에 대한 구체적인 사항은 회사의 정책에 따릅니다.
              </li>
            </ol>
          </section>


          {/* 제8조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 8 조 (책임의 제한)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                회사는 천재지변, 시스템 장애 등의 사유로 인해 서비스 제공이 불가능한 경우 책임을 지지 않습니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                이용자가 본 약관을 위반하여 발생한 손해에 대해 회사는 책임을 지지 않습니다.
              </li>
            </ol>
          </section>

          {/* 제8조 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>제 9 조 (준거법 및 관할)</h2>
            <ol style={{
              paddingLeft: '20px',
              listStyleType: 'decimal'
            }}>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                본 약관은 대한민국 법률에 따라 해석됩니다.
              </li>
              <li style={{
                marginBottom: '8px',
                color: '#4B5563',
                lineHeight: '1.6',
                fontSize: '14px'
              }}>
                본 약관과 관련하여 발생한 분쟁에 대해서는 대한민국의 법원을 관할 법원으로 합니다.
              </li>
            </ol>
          </section>
          {/* 나머지 조항들도 같은 형식으로 계속... */}

          {/* 부칙 */}
          <section style={{ marginBottom: '24px' }}>
            <h2 style={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '8px',
              color: '#29333E'
            }}>부칙</h2>
            <p style={{
              color: '#4B5563',
              lineHeight: '1.6',
              fontSize: '14px'
            }}>
              본 약관은 2025년 1월 30일부터 시행됩니다.
            </p>
          </section>



        </div>
      </div>
  );
};

export default TermsPage;
