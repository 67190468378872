import ic_back from "../../../images/ic_back.png";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {findMissionMembers} from "../../../api/MissionFriendsSettingApi";

const MissionFriendsSetting = () => {
  const location = useLocation();
  const missionId = location.state?.missionId;
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);

  const handleBack = () => {
    navigate("/mission-mate/mission-setting", { state: { missionId } });
  }

  const fetchMissionMembers = async () => {
    try {
      const response = await findMissionMembers(missionId, token, navigate);
      setMembers(response.findMissionMembersResponses);
    } catch (error) {
      console.error("Failed to fetch mission members:", error);
    }
  };

  useEffect(() => {
    fetchMissionMembers();
  }, [missionId, token]);

  return (
      <div>
        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '21px',
              position: 'relative',
              marginBottom: '11px',
            }}
        >
          <img
              src={ic_back}
              onClick={handleBack}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '16px',
                marginRight: '16px',
                position: 'absolute',
                cursor: 'pointer',
              }}
          />
          <p style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '26px',
            letterSpacing: '-0.54px',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}>
            프렌즈 관리
          </p>
        </div>

        <div style={{
          marginTop: '35px',
        }}>
          {members.map((member) => (
              <div
                  key={member.memberId}
                  style={{
                    display: 'flex',
                    paddingTop: '14px',
                    paddingBottom: '14px',
                    alignItems: 'center',
                    borderRadius: '8px',
                    background: '#EBEFF6',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                    marginLeft: '16px',
                    marginRight: '16px',
                  }}
              >
                <span style={{
                  color: '#29333E',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.42px',
                  marginLeft: '12px',
                }}>
              {member.nickName}
            </span>
                {member.requester && (
                    <span style={{
                      marginLeft: '4px',
                      color: '#227AF3',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '-0.42px',
                      marginRight: '12px',
                    }}>
                나
              </span>
                )}
              </div>
          ))}
        </div>
      </div>
  );
}

export default MissionFriendsSetting;
