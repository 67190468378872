import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { LoginToken } from "../../redux/UserStore";
import { useDispatch, useSelector } from "react-redux";
import {postGuestLogin} from "../../api/GuestLoginApi";


const GuestLoginButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGuestLogin = async () => {
    const response = await postGuestLogin();
    dispatch(LoginToken(response.accessToken));
    navigate("/mission-mate");
  }

  return (
      <div
          style={{
            borderRadius: '8px',
            background: 'var(--Grayscale-White, #FFF)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '328px',
            height: '52px',
            marginBottom: '16px',
          }}
      >
        <p
            style={{
              color: '#161616',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '20px',
              letterSpacing: '-0.42'
            }}
            onClick={handleGuestLogin}>Guest Login</p>
      </div>
  )
}

export default GuestLoginButton;
