import React from 'react';

const PopupPage = ({
  isOpen,
  title,
  description,
  leftButtonText = '취소',
  rightButtonText = '확인',
  onLeftClick,
  onRightClick,
  onClose
}) => {
  if (!isOpen) return null;

  return (
      <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}
          onClick={onClose}
      >
        <div
            style={{
              backgroundColor: 'white',
              width: '100%',
              marginLeft: '20px',
              marginRight: '20px',
              borderRadius: '8px',
              paddingTop: '32px',
            }}
            onClick={e => e.stopPropagation()}
        >
          <p
              style={{
                color: '#29333E',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
              }}
          >
            {title}
          </p>
          <p
              style={{
                color: '#29333E',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '-0.42px',
                marginBottom: '32px',
              }}
          >
            {description}
          </p>

          <div
              style={{
                display: 'flex',
                width: '100%',
              }}
          >
            <button
                style={{
                  flex: 1,
                  height: '52px',
                  padding: '12px 0',
                  border: 'none',
                  background: '#CFD9E2',
                  color: '#29333E',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  borderBottomLeftRadius: '8px'
                }}
                onClick={() => {
                  onLeftClick?.();
                  onClose();
                }}
            >
              {leftButtonText}
            </button>
            <button
                style={{
                  flex: 1,
                  height: '52px',
                  textAlign: 'center',
                  letterSpacing: '-0.42px',
                  padding: '12px 0',
                  border: 'none',
                  background: '#227AF3',
                  color: '#FFFFFF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  borderBottomRightRadius: '8px'
                }}
                onClick={() => {
                  onRightClick?.();
                  onClose();
                }}
            >
              {rightButtonText}
            </button>
          </div>
        </div>
      </div>
  );
};

export default PopupPage;
