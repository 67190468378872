import { Routes, Route } from 'react-router-dom';
import {useEffect, useState} from 'react';  // 상단에 추가
import './App.css';
import LoginPage from "./page/login/LoginPage";
import KakaoLoginPage from "./page/login/KakaoLoginPage";
import Main from "./page/Main";
import AppleLoginButton from "./page/login/AppleLoginButton";
import GoogleLoginButton from "./page/login/GoogleLoginButton";
import ProfileSetupPage from "./page/profile/ProfileSetupPage";
import MissionListPage from "./page/mission/list/MissionListPage";
import MissionDetailPage from "./page/mission/detail/MissionDetailPage";
import UserSettingPage from "./page/setting/UserSettingPage";
import MissionCreatePage from "./page/mission/create/MissionCreatePage";
import MissionSettingPage from "./page/mission/setting/MissionSettingPage";
import MissionUpdatePage
  from "./page/mission/setting/misson-update/MissionUpdatePage";
import NotificationSettingPage
  from "./page/setting/notification/NotificationSettingPage";
import MissionFriendsSetting
  from "./page/mission/setting/MissionFriendsSetting";
import SettingPageInfo from "./page/setting/info/SettingPageInfo";
import TermsPage from "./page/setting/info/TermsPage";
import PrivacyPolicy from "./page/setting/info/PrivacyPolicy";
import GuestLoginButton from "./page/login/GuestLoginButton";

function App() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <div className="page-container">  {/* 새로운 div 추가 */}
        <Routes>
          <Route path="/mission-mate" element={<Main />} />
          <Route path="/mission-mate/login" element={<LoginPage />} />
          <Route path="/mission-mate/auth/login" element={<KakaoLoginPage />} />
          <Route path="/mission-mate/auth/login" element={<GuestLoginButton />} />
          <Route path="/mission-mate/auth/login/apple" element={<AppleLoginButton />} />
          <Route path="/mission-mate/auth/login/google" element={<GoogleLoginButton />} />
          <Route path="/mission-mate/profile-setup" element={<ProfileSetupPage />} />
          <Route path="/mission-mate/mission-list" element={<MissionListPage />} />
          <Route path="/mission-mate/mission-detail" element={<MissionDetailPage />} />
          <Route path="/mission-mate/profile-setting" element={<UserSettingPage />} />
          <Route path="/mission-mate/mission-create" element={<MissionCreatePage />} />
          <Route path="/mission-mate/mission-update" element={<MissionUpdatePage />} />
          <Route path="/mission-mate/mission-setting" element={<MissionSettingPage />} />
          <Route path="/mission-mate/notification-setting" element={<NotificationSettingPage />} />
          <Route path="/mission-mate/friends-setting" element={<MissionFriendsSetting />} />
          <Route path="/mission-mate/setting-info" element={<SettingPageInfo />} />
          <Route path="/mission-mate/setting-terms-info" element={<TermsPage />} />
          <Route path="/mission-mate/setting-privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        </div>
      </header>
    </div>
  );
}

export default App;
