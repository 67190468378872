import React, {useEffect} from "react";
import {getMemberInfo} from "../../api/ProfileSetupPageApi";

const NickNameInput = ({onChange, defaultNickname}) => {
  useEffect(() => {
  }, [defaultNickname]);

  return (
      <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
      >
        <input
            type="text"
            placeholder={defaultNickname || "닉네임 입력"}
            style={{
              display: 'flex',
              width: '100%',
              padding: '14px 12px',
              alignItems: 'center',
              borderRadius: '8px',
              border: '1px solid var(--Grayscale-Gray_30, #B2BFCC)',
              marginTop: '30px',
              marginLeft: '16px',
              marginRight: '16px',
              boxSizing: 'border-box',    // 추가
              color: 'var(--Grayscale-Gray_30, #B2BFCC)',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.42px',
            }}
            onFocus={(e) => {
              e.target.style.outline = 'none'; // 기본 파란색 제거
              e.target.style.border = '1px solid #888'; // 원하는 테두리 스타일 적용
            }}
            onBlur={(e) => {
              e.target.style.border = '1px solid var(--Grayscale-Gray_20, #CFD9E2)'; // 포커스 해제 시 원래 스타일 복구
            }}
            onChange={(e) => onChange(e.target.value)} // 입력 값 부모로 전달
        />
      </div>
  );
};

export default NickNameInput;
