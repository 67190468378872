import ic_back from "../../../images/ic_back.png";
import React from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ic_right from "../../../images/ic_right.png";

const SettingPageInfo = () => {

  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  return (
      <div>

        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '21px',
              position: 'relative',
            }}
        >
          <img
              onClick={() => navigate('/mission-mate/profile-setting')}
              src={ic_back}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '16px',
                position: 'absolute',
              }}
          />
          <p style={{
            color: '#29333E',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '26px',
            letterSpacing: '-0.54px',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}>
            정보
          </p>
        </div>


        {/* 이용약관 */}
        <div
            onClick={() => navigate('/mission-mate/setting-terms-info')}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '40px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
          >
            이용약관
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
              }}
          />
        </div>


        {/* 개인정보 정책 */}
        <div
            onClick={() => navigate('/mission-mate/setting-privacy-policy')}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '32px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
          >
            개인정보 정책
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
              }}
          />
        </div>

        {/* 개인정보 정책 */}
        <div
            onClick={() => navigate('/mission-mate/setting-info')}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '32px',
            }}
        >
          <p
              style={{
                color: '#29333E',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.48px',
                marginLeft: '16px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
          >
            오픈소스
          </p>
          <img
              src={ic_right}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '16px',
              }}
          />
        </div>

      </div>
  )
}

export default SettingPageInfo;
