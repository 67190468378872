import React, {useEffect, useState} from 'react';
import ProfileSetupPreview from "./ProfileSetupPage";
import {findAll} from "../../api/ProfileSetupPageApi";

const ProfileSetupPage = ({setProfileUrl}) => {
  const [images, setImages] = useState([]);
  const [selectedId, setSelectedId] = useState(1);

  const handleSelect = (id, url) => {
    setSelectedId(id === selectedId ? null : id);
    setProfileUrl(url);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await findAll();
        setImages(response.profileImageResponses);
        if (response.profileImageResponses.length > 0) {
          setProfileUrl(response.profileImageResponses[0].imageUrl);
        }
      } catch (error) {
        console.error('데이터 로드 실패:', error);
      }
    };
    loadData();
  }, []);

  return (
      <div style={{
        display: 'flex',
        overflowX: 'auto',
        gap: '8px',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        marginTop: '24px',
      }}>

        {images.map((image) => (
            <div
                onClick={() => handleSelect(image.profileImageId, image.imageUrl)}
                key={image.profileImageId}  // 고유한 key 추가
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '44px',
                  height: '44px',
                  padding: '8px',
                  border: image.profileImageId === selectedId ? '1px solid #227AF3' : '1px solid transparent',
                  borderRadius: '100px',
                  background: image.profileImageId === selectedId ? '#C0DFF8': '#E5F2FC',
                }}
            >
            <img
                id={image.id}
                src={image.imageUrl}
                alt={`Profile ${image.id}`}  // 접근성을 위한 alt 텍스트 추가
                style={{
                  width: '44px',
                  height: '44px',
                }}
            />

            </div>
        ))}
      </div>
  );
};

export default ProfileSetupPage;
