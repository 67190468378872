import React, {useEffect, useState} from 'react';
import profile from "../../../images/profile_1.png";
import {useSelector} from "react-redux";
import {createInviteUrl} from "../../../api/MissionInviteApi";
import {useNavigate} from "react-router-dom";

const MissionInvite = ({missionId}) => {

  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  // URL 상태 추가
  const [inviteUrl, setInviteUrl] = useState('');

  // URL 생성 함수
  const generateInviteUrl = async () => {
    try {
      const response = await createInviteUrl(
          missionId,
          token,
          navigate
      );
      setInviteUrl(response);
      return response;
    } catch (error) {
      console.error('초대 URL 생성 실패:', error);
      return null;
    }
  };


  // 링크 복사 핸들러
  const handleCopyLink = async () => {
    const url = inviteUrl || await generateInviteUrl();
    if (!url) return;

    try {
      if (window.clipboardData) {
        window.clipboardData.setData('text', url);
        alert("초대 링크가 클립보드에 복사되었습니다!");
      } else {
        await navigator.clipboard.writeText(url);
        alert("초대 링크가 클립보드에 복사되었습니다!");
      }
    } catch (error) {
      console.error('클립보드 복사 실패:', error);
    }
  };

  // 공유 핸들러
  const handleShare = async () => {
    const url = inviteUrl || await generateInviteUrl();
    if (!url) return;

    // iOS WebView에서 주입한 공유 메서드가 있는지 확인
    if (window.webkit?.messageHandlers?.share) {
      // iOS 네이티브 공유 기능 호출
      window.webkit.messageHandlers.share.postMessage({
        title: '미션 초대',
        text: '함께 미션을 달성해보아요!',
        url: url
      });
    } else if (navigator.share) {
      // 기존 Web Share API 사용
      try {
        await navigator.share({
          title: '미션 초대',
          text: '함께 미션을 달성해보아요!',
          url: url
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('공유 실패:', error);
          alert('공유하기를 실패했습니다. 링크를 복사해서 공유해주세요.');
        }
      }
    } else {
      // 모든 방법 실패시 클립보드 복사
      handleCopyLink();
      alert('이 브라우저는 공유하기 기능을 지원하지 않습니다. 링크를 복사해서 공유해주세요.');
    }
  };

  return (
      <div
          style={{
            backgroundColor: '#EBEFF6',
          }}
      >

        <div
            style={{
              display: 'flex',
              paddingTop: '24px',
              paddingLeft: '16px',
            }}
        >
          <img
              src={profile}
              style={{}}
          />
          <p
              style={{
                color: '#4A5B6C',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                letterSpacing: '-0.42px',
                marginLeft: '16px',
              }}
          >
            함께 미션을 달성하고 싶은 친구를 초대해요!
          </p>
        </div>

        <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              marginLeft: '16px',
              marginRight: '16px',
              marginTop: '12px',
              paddingBottom: '24px',
            }}
        >
          <div
              onClick={handleCopyLink}
              style={{
                flex: 1,     // 추가
                borderRadius: '8px',
                backgroundColor: '#5A6F83',
                paddingTop: '14px',
                paddingBottom: '14px',
              }}
          >
            <p
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '16px',
                  margin: '0px',
                  letterSpacing: '-0.36px',
                }}
            >
              링크 복사하기
            </p>
          </div>

          <div
              onClick={handleShare}  // 수정
              style={{
                flex: 1,     // 추가
                borderRadius: '8px',
                backgroundColor: '#227AF3',
                paddingTop: '14px',
                paddingBottom: '14px',
              }}
          >
            <p
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '16px',
                  letterSpacing: '-0.36px',
                  margin: '0px'
                }}
            >
              링크 바로 공유하기
            </p>
          </div>
        </div>

      </div>
  );
}

export default MissionInvite;
