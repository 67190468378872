import axios from 'axios';
import {url} from './ApiConstant';

export async function update(nickname, gender, birth, profileImageUrl, token,
    navigate) {
  try {
    console.log("Sending data:", {nickname, gender, birth});
    const response = await axios.post(
        `${url}/members/update`,
        {nickname, gender, birth, profileImageUrl},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', {replace: true}); // 로그인 페이지로 리디렉션
    }
  }
}

export async function findAll() {
  try {
    const response = await axios.get(
        `${url}/profile`,
        {
          headers: {},
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
    }
  }
}

export async function deleteMember(token) {
  try {
    const response = await axios.post(
        `${url}/members/delete`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
    }
  }
}

export async function getMemberInfo(token) {
  try {
    const response = await axios.get(
        `${url}/members/info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
    }
  }
}
