import React, { useRef } from 'react';
import AppleLogin from 'react-apple-login';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginToken } from "../../redux/UserStore";
import {url} from "../../api/ApiConstant";
import loginButton from "../../images/btn_apple_login.png";

const REDIRECT_URI = "https://one-daily-app.shop/mission-mate/auth/login/apple";
// const REDIRECT_URI = "http://localhost:3000/mission-mate/auth/login/apple";

const AppleLoginButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cardRef = useRef(null);

  const handleCallback = async (response) => {
    try {
      const { code, id_token } = response.authorization;
      const res = await fetch(`${url}/auth/apple/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code, id_token })
      });

      const data = await res.json();
      dispatch(LoginToken(data.accessToken));
      navigate("/mission-mate");
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const renderProp = (props) => {
    return (
        <img
            src={loginButton}
            alt="Sign in with Apple"
            style={{
              width: '328px',
              height: '52px',
              cursor: props.disabled ? 'default' : 'pointer',
            }}
            onClick={props.onClick}
        />
    );
  };

  return (
      <div ref={cardRef} style={{
        width: '328px',  // 이미지 너비와 동일하게 수정
        height: '52px',  // 이미지 높이와 동일하게 수정
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '16px',
        padding: '0',
      }}>
        <AppleLogin
            clientId="com.missionmate.login-service"
            redirectURI={REDIRECT_URI}
            responseType="code id_token"
            responseMode="query"
            scope="name email"
            usePopup={true}
            extraParams={{ prompt: "consent" }} // 동의 다시 요청
            callback={handleCallback}
            render={renderProp}
        />
      </div>
  );
};

export default AppleLoginButton;
