import axios from 'axios';
import {url} from './ApiConstant';

export async function existProfileImage(token, navigate) {
  try {
    const response = await axios.get(
        `${url}/members/exist-profile-image`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      navigate('/mission-mate/login', { replace: true }); // 로그인 페이지로 리디렉션
    }
  }
}
